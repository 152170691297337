import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Col, Modal, Nav, NavItem, Row, Tab } from 'react-bootstrap';
import ModalProfileImage from '../../../../../components/common/ModalProfileImage';
import { useModalHandler, usePageInitialtor } from '../../../../../helper/custom_hook';
import { examination } from '../../../../../helper/api_url';
import { AddQuestionModal } from '../QuestionModals/AddQuestionModal';
import CustomSelect from '../../../../../components/CustomSelect';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../../../features/Ui/uiSlice';
import { swal } from '../../../../../helper/swal';
import { subjects } from '../../../../../helper/constants';

export function SetQuestionsModal(prop) {
    const {data,setData,courses} = prop;
    const { toggleModal, status } = useModalHandler()
    const dispatch = useDispatch()
    const [tableData, setTableData] = useState([])
    const [tabIndex, setTabIndex] = useState(0);
    const changeTab = (index) => setTabIndex(index);
    const inactive_tab_classes = "d-inline-block text-center bg-soft-primary w-100 px-4 py-2 rounded";
    const active_tab_classes = "d-inline-block text-center bg-primary text-white w-100 px-4 py-2 rounded";
    const handleCheckboxChange = (event, question) => {
        const checked = event.target.checked;
        if(checked) setData(st=>st.find(q=>q.id === question.id)?st:[...st,{...question,marks:0}])
        else setData(st=>st.filter(q=>q.id !== question.id))
    };
    useEffect(() => {
        if (status)
            examination.readQuestions().then(res => setTableData(res.data.question))
                .catch(err => console.log(err))
    }, [status])

    const handleSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        dispatch(setPreloader({ loader: true, message: 'adding Question please wait ..' }))
        examination.createQuestion(formData)
            .then(res => {
                toggleModal()
            }).catch(e => swal.error(e.response ? e.response?.data?.message : e.message))
            .finally(() => dispatch(setPreloader({ loader: false, message: '' })))
    }
    return (
        <>
            {prop.children ? (
                <div onClick={toggleModal}>{prop.children}</div>
            ) : (
                <button onClick={toggleModal} className='btn btn-soft-success w-100'>
                    Create/Add Questions
                </button>
            )}
            <Modal size='lg' show={status} onHide={toggleModal} className="fade" centered={true} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Create/Add Questions</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='position-relative'>
                        <Nav variant='' className="d-flex justify-content-center align-items-center px-4">
                            <NavItem className='me-2' style={{ width: '164px' }}>
                                <NavLink onClick={e => changeTab(0)} className={tabIndex === 0 ? active_tab_classes : inactive_tab_classes}>Select Questions</NavLink>
                            </NavItem>
                            <NavItem className='ms-2' style={{ width: '164px' }}>
                                <NavLink onClick={e => changeTab(1)} className={tabIndex === 1 ? active_tab_classes : inactive_tab_classes}>Add Question</NavLink>
                            </NavItem>
                        </Nav>
                        <div class="">
                            <Tab.Container activeKey={tabIndex}>
                                <Tab.Content>
                                    <Tab.Pane eventKey={0}>
                                        <div className='mt-3'>
                                            <hr />
                                            <Row>
                                                <Col xs={5}>
                                                    <label htmlFor="" className="form-label">Select Class</label>
                                                    <CustomSelect 
                                                        isSearchable
                                                        options={courses.map(c=>({label:c.name,value:c.id}))} 
                                                        name="class_id" 
                                                        placeholder="select subject" 
                                                        className="w-100"
                                                    />
                                                </Col>
                                                <Col xs={5} className="mb-2">
                                                    <label htmlFor="" className="form-label">Select Subject</label>
                                                    <CustomSelect 
                                                        options={subjects}
                                                        name="class_id"
                                                        placeholder="select subject"
                                                        className="w-100"
                                                    />
                                                </Col>
                                                <Col xs={2} className="d-flex mb-2 align-items-end"><button className='btn btn-success w-100'>Apply Filter</button></Col>
                                            </Row>
                                            <ul className='list-group mt-3'>
                                                {tableData.map((x, i) => (
                                                    <li className='list-group-item' htmlFor={"flexCheckChecked_"+i}>     
                                                        <div key={i} class="form-check">
                                                            <input 
                                                                class="form-check-input" 
                                                                type="checkbox"
                                                                id={"flexCheckChecked_"+i}
                                                                checked={data.some(q=>q.id==x.id)}
                                                                onChange={(e) => handleCheckboxChange(e, x)} 
                                                            />
                                                            <label class="form-check-label" htmlFor={"flexCheckChecked_"+i}>{x.question}</label>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={1}>
                                        <div className='mt-3'>
                                            <hr />
                                            <form onSubmit={handleSubmit}>
                                                <div className="row g-3">
                                                    <div className="col-6">
                                                        <label htmlFor="class_id" className="form-label">Class</label>
                                                        <CustomSelect 
                                                            isSearchable
                                                            options={courses.map(c=>({label:c.name,value:c.id}))} 
                                                            name="class_id" 
                                                            placeholder="select subject" 
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label htmlFor="course_subject" className="form-label">Subject</label>
                                                        <CustomSelect 
                                                            options={subjects}
                                                            id='course_subject' 
                                                            name="subject"
                                                            placeholder="select subject"
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <div>
                                                            <label htmlFor="course_question" className="form-label">Question</label>
                                                            <textarea rows={5} className="form-control" id='course_question'  name="question" placeholder="Enter course question" ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mt-3">
                                                    <div className="hstack gap-2 justify-content-end">
                                                        <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </Modal.Body>
                {tabIndex === 0 && (
                    <Modal.Footer>
                        <div className="col-lg-12">
                            <div className="hstack gap-2 justify-content-end">
                                <button type="submit" className="btn btn-primary" onClick={toggleModal}>OK</button>
                            </div>
                        </div>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    );
}
