import React, {useMemo} from 'react'
import BreadCrumb from "../../../components/common/BreadCrumb";
import { Card, CardBody, CardHeader, Col, Row, Button } from "react-bootstrap";
import { TableResponsive } from "../../../components/common/TableResponsive";
import { NewRoleModal } from './NewRoleModal';
import {  UpdateRoleModal } from './UpdateRoleModal';
import { role } from '../../../helper/api_url';
import { getPriorityValue } from '../../../helper/constants';
import { usePageInitialtor } from '../../../helper/custom_hook';
function Role() {
    const {tableData,apiHandler} = usePageInitialtor(role);
    const handleRoleDelete = (roleId,name) =>apiHandler.handleDelete({name:name,id:roleId});
    const columns = useMemo(()=>[
        {Header: "Role Id",accessor: "id",HeaderClass:'w-10'},
        {Header: "Name",accessor: "name"},
        {Header: "Type",accessor: "type"},
        {
            Header: "Priority",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell:(cell)=>getPriorityValue(cell.row.original.priority)?(<span className={`text-${getPriorityValue(cell.row.original.priority).color}`}>{getPriorityValue(cell.row.original.priority).key}</span>):''
        },
        {
            Header: "Action",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell: (cell) => {
              return ( 
                <div className="">
                    {/* <Button className="btn btn-sm btn-soft-primary me-1" >
                        <i className="ri-eye-fill" /> 
                    </Button> */}
                    <UpdateRoleModal data={cell.row.original} apiHandler={apiHandler}   />
                    {/* <Button onClick={()=>handleRoleDelete(cell.row.original.id,cell.row.original.name)} className="btn btn-sm btn-soft-danger me-1" >
                        <i className="ri-delete-bin-fill" />  
                    </Button> */}
                </div>
              )
            },
        },
        {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                return (
                <div className="d-flex">
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#" className="link text-dark"></a>
                            <a href="#">{row.name}</a>
                            <span className="badge badge-soft-primary px-1 ms-1">{row.type}</span>
                        </h5>
                        <p className="">
                            Priority :{getPriorityValue(row.priority)?( <span className={`text-${getPriorityValue(row.priority).color}`}>
                                {getPriorityValue(row.priority).key}
                            </span>):''} | Users : 12
                        </p>
                    </div>
                    <div className="flex-shrink-0">
                        <div>
                        <UpdateRoleModal data={row} apiHandler={apiHandler}   />
                            {/* <button onClick={()=>handleRoleDelete(row.id,row.name)} className="btn btn-sm btn-soft-danger me-1" data-id="1"> <i className="ri-delete-bin-fill"></i> </button> */}
                        </div>
                    </div>
                </div>
                )
            }
        }
    ]);
    return (
        <>
            <BreadCrumb title="Role" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Role List</h5>
                            <NewRoleModal apiHandler={apiHandler}/>
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive columns={columns} data={tableData} noRecord="No Record Role List" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Role