import React from 'react'
import { Pie } from 'react-chartjs-2';
import {Chart as ChartJs} from "chart.js/auto";
import {Card, CardBody} from "react-bootstrap";

function PieChart({chartData}) {
    return (
        <Card>
            <CardBody>
                <Pie data={chartData}  />
            </CardBody>
        </Card>
    )
}

export default PieChart