import LoginLogo from "../assets/images/user-illustarator-2.png";
import logo2 from "../assets/images/logo-sm.png";
import logo1 from "../assets/images/clientlogo/logo2.png";
import logo3 from "../assets/images/logo-dark-old.png";
import logo from "../assets/images/logo-dark-old.png";
import courseLogo1 from "../assets/images/clientlogo/hero-abs-1.png";
import courseLogo2 from "../assets/images/clientlogo/hero-abs-2.png";
import courseLogo3 from "../assets/images/clientlogo/hero-abs-3.png";
import courseLogo4 from "../assets/images/clientlogo/hero-abs-4.png";
import courseLogo5 from "../assets/images/clientlogo/hero-banner.png";

import profile from "../assets/images/clientlogo/profile.jpg";
import teambg from "../assets/images/clientlogo/cta-bg.png";
import coursebg from "../assets/images/clientlogo/newsletter-bg.jpg";
import icon1 from "../assets/images/clientlogo/icon1.png";
import icon2 from "../assets/images/clientlogo/icon2.png";
import icon3 from "../assets/images/clientlogo/icon3.png";
import profilebg from "../assets/images/clientlogo/profilebg.jpg";

import aboutLogo from "../assets/images/clientlogo/aboutLogo.png";
import camerapic from "../assets/images/clientlogo/camera.png";
import faqIllustration from "../assets/images/faq-img.png";
export default {
  faqIllustration,
  logo,
  logo2,
  LoginLogo,
  courseLogo1,
  profilebg,
  courseLogo2,
  courseLogo3,
  courseLogo4,
  courseLogo5,
  profile,
  teambg,
  coursebg,
  aboutLogo,
  icon1,
  icon2,
  icon3,
  camerapic,
};
