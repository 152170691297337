import React, { useEffect, useRef, useState } from 'react';
import './printable.css';
import { Container, Row, Col, Table, Card, CardBody, CardHeader, CardFooter } from 'react-bootstrap';
import { student } from '../../../../helper/api_url';
import { setPreloader } from '../../../../features/Ui/uiSlice';
import { swal } from '../../../../helper/swal';
import { useReactToPrint } from 'react-to-print';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const DemandLetter = () => {
    const { studentID } = useParams();
    const [studentData, setStudentData] = useState({});
    const componentRef = useRef();
    const dispatch = useDispatch()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        dispatch(setPreloader({ loader: true, message: 'Loading Please Wait ....' }))
        student.profile(studentID)
            .then((data) => setStudentData(data.data.profile))
            .catch((err) => swal.error(err.response ? err.response.data.message : err.message))
            .finally(() => dispatch(setPreloader({ loader: false, message: '' })));
    }, []);
    return (
        <Container className="mt-4">
            <div className='mb-4 d-flex justify-content-center'>
                <button type='button' onClick={handlePrint} className='btn btn-soft-danger'>Print</button>
            </div>
            <Card ref={componentRef} id="printable-area">
                <CardHeader className="text-center">
                    <img
                        src="https://via.placeholder.com/1500x200?text=Place+Holder+Image"
                        alt="Placeholder"
                        className="img-fluid"
                    />
                </CardHeader>
                <CardBody className='px-4'>
                    <Row className="bg-primary rounded text-center py-2 mb-3">
                        <Col>
                            <h5 className='text-white m-0'>DEMAND LETTER (MAY 2024)</h5>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs="6">
                            <p><strong>Demand No.:</strong> 2024-25/1086</p>
                            <p><strong>Student name:</strong> Ananya Chaurasiya</p>
                            <p><strong>Enrollment ID:</strong> 392</p>
                            <p><strong>Class:</strong> 4</p>
                        </Col>
                        <Col xs="6">
                            <p><strong>Generated date:</strong> 24/05/2024</p>
                            <p><strong>Parent/Guardian name:</strong> Govinda Kumar</p>
                            <p><strong>Payment due:</strong> 1500</p>
                            <p><strong>Section:</strong> A</p>
                        </Col>
                    </Row>
                    <Row className="bg-primary rounded text-center py-2 mb-3">
                        <Col>
                            <h5 className='text-white m-0'>Fee details</h5>
                        </Col>
                    </Row>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Description</th>
                                <th>Pending dues</th>
                                <th>Current month amount</th>
                                <th>Total amount</th>
                                <th>Fee dues</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Back dues of session 2023-24</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Transport fee</td>
                                <td>650</td>
                                <td>650</td>
                                <td>1300</td>
                                <td>650</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Tuition fee</td>
                                <td>850</td>
                                <td>850</td>
                                <td>1700</td>
                                <td>850</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td></td>
                                <td>1500</td>
                                <td>1500</td>
                                <td>3000</td>
                                <td>1500</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Row className="mb-3">
                        <Col xs="6">
                            <p><strong>Total fee:</strong> 3000</p>
                            <p><strong>Total paid:</strong> 1500</p>
                        </Col>
                        <Col xs="6">
                            <p><strong>Total due:</strong> 1500</p>
                            <p><strong>Payment deadline:</strong> 10/05/2024</p>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <p><strong>Accountant:</strong> +91-7979809772</p>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col>
                            <p>This is a computer generated demand letter and does not require signature.</p>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Container>
    );
};

export default DemandLetter;
