import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { course } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { Card, CardBody, CardHeader, Col, Container, Row, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useReactToPrint } from 'react-to-print';
import CustomSelect from '../../../components/CustomSelect'
import './printInvoice.css';

function ClassAdmitCard() {
    const { class_id } = useParams();
    const [currentCourse, setCurrentCourse] = useState({});
    const [currentCourseStudents, setCurrentCourseStudents] = useState([]);
    const client = useSelector(state => state.auth.client);
    const App_logo = useSelector(state => state.auth.LOGO);
    const [selectedStudent,setSelectedStudent] = useState(null);
    useEffect(() => {
        course.select(class_id).then(res => setCurrentCourse(res.data.course))
            .catch(e => swal.error('Something went Wrong', e.response ? e.response.data.message : e.message));
        course.students(class_id).then(res => setCurrentCourseStudents(res.data.student))
            .catch(e => swal.error('Something went Wrong', e.response ? e.response.data.message : e.message));
    }, [class_id]);
    const currentDate = new Date();
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Chakia Central School Admit Card " + currentCourse.name + " - " + currentCourseStudents.find(cs=>cs.id === selectedStudent)?.first_name ,
    });
    return (
        
        <>
            <CustomSelect isSearchable onChange={e=>setSelectedStudent(e.value)}  options={currentCourseStudents.map(ccs=>({label: `${ccs.first_name} ${ccs.last_name} #CCS${ccs.id}`,value:ccs.id}))} />
            <div className='mb-4 d-flex justify-content-center'>
                <button type='button' onClick={handlePrint} className='btn btn-soft-danger'>Print</button>
            </div>
            <div id={'printable-area-st'} ref={componentRef}>
                { selectedStudent && currentCourseStudents.map((cs,index)=>cs.id === selectedStudent && (
                    <Container id={'container'} key={index} className=" card mt-4 w-100">
                        <Card id="printable-area">
                            <CardHeader className="d-flex justify-content-start p-3 align-items-center position-relative">
                                <img
                                    src={App_logo}
                                    alt="Placeholder"
                                    className="rounded"
                                    style={{
                                        aspectRatio: '1/1',
                                        objectFit: "cover",
                                        width: "112px"
                                    }}
                                />
                                <div className='d-flex justify-content-center align-items-center flex-column w-100 position-absolute'>
                                    <h1>{client?.business_name || 'School Name'}</h1>
                                    <p className='mb-1'>{client?.business_address}</p>
                                    <p>{client?.business_city} {client?.business_state} - {client?.business_pin}</p>
                                </div>
                            </CardHeader>
                            <CardBody className='px-4'>
                                <Row className="mb-3">
                                    <Col xs="6">
                                        <p><strong>Student name:</strong> {cs.first_name} {cs.last_name}</p>
                                        <p><strong>Enrollment ID:</strong> #CCS{cs.id}</p>
                                        <p><strong>Roll no:</strong> {index+1}</p>
                                    </Col>
                                    <Col xs="6">
                                        <p><strong>Generated date:</strong> {format(currentDate,"PPP")}</p>
                                        <p><strong>Class:</strong> {currentCourse.name}</p>
                                        <p><strong>Father's Name:</strong> {cs.personal_details?.parents?.father_name}</p>
                                    </Col>
                                </Row>
                                <Row className="bg-primary rounded text-center py-2 mb-3">
                                    <Col>
                                        <h5 className='text-white m-0'>Admit Card</h5>
                                    </Col>
                                </Row>
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Exma date </th>
                                            <th>Subject</th>
                                            <th>Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>03/10/2024</td>
                                            <td>English</td>
                                            <td>9:45 AM - 11:45 AM</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>04/10/2024</td>
                                            <td>Hindi</td>
                                            <td>9:45 AM - 11:45 AM</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>05/10/2024</td>
                                            <td>Maths</td>
                                            <td>9:45 AM - 11:45 AM</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>07/10/2024</td>
                                            <td>Science</td>
                                            <td>9:45 AM - 11:45 AM</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>08/10/2024</td>
                                            <td>Social Science</td>
                                            <td>9:45 AM - 11:45 AM</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>09/10/2024</td>
                                            <td>GK</td>
                                            <td>9:30 AM - 10:30 AM</td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>09/10/2024</td>
                                            <td>Computer</td>
                                            <td>10:45 AM - 12:00 PM</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                
                                <Row className="text-center">
                                    <Col>
                                        <p>This is a computer generated Admit Card and does not require signature.</p>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Container>
                ))}
            </div>
        </>

    )
}

export default ClassAdmitCard