import React from 'react'
import { Modal} from 'react-bootstrap'
import {  role } from '../../../helper/api_url'
import { priority } from '../../../helper/constants';
import { Tooltip } from 'antd';
import {useModalHandler} from '../../../helper/custom_hook';
export function UpdateRoleModal({ data, apiHandler }) {
    const {toggleModal,status} = useModalHandler();
    const priorityTypeDesc=<ul className="list-group">
                            <span className='text-center'>Accessable</span>
                            <li>Highest Priority: Menu, Modules, Setting</li>
                            <li>Medium Priority: Menu, </li>
                            </ul>;
    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-success me-1'><i className="ri-pencil-fill"></i>  </button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Update Only Role priority</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={e => apiHandler.handleUpdate(e,role.update,toggleModal)}>
                        <div className="row g-3">
                            <input type="hidden" name="role_id" defaultValue={data.id} />
                            <div className="col-12">
                                <div>
                                    <label htmlFor="roleName" className="form-label">Role Name</label>
                                    <input type="text" className="form-control" id='roleName' name="name" defaultValue={data.name} readOnly placeholder="Enter Role Name" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="type" className="form-label">Role Type</label>
                                    <input type="text" className="form-control" id='type' name="type" defaultValue={data.type} readOnly placeholder="Enter Role type" />
                                </div>
                            </div>

                            <div className="col-6">
                                <div>
                                    <label htmlFor="priority" className="form-label">Priority</label>
                                    <Tooltip  placement="top" title={priorityTypeDesc} ><i className='text-danger ps-1 align-center bx bx-help-circle' /></Tooltip>
                                    <select className='form-control' name='priority'>
                                        {data.priority && <option defaultValue={data.priority}>{data.priority}</option>}
                                        {priority.map((p, id) => (
                                            <option key={id} value={p.value}>{p.key}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-4">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                    <button type="submit" className="btn btn-primary">Update</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    );
}