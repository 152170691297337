import React, { useEffect, useMemo, useState } from 'react'
import { ASSET_URL, fee } from '../../../helper/api_url'
import BreadCrumb from '../../../components/common/BreadCrumb'
import { Card, CardBody, CardHeader, Col, Row } from "react-bootstrap"
import { TableResponsive } from '../../../components/common/TableResponsive'
import DuesDescriptionModal from './DuesDescriptionModal'
import FeesPaymentModal from './FeesPaymentModal'
import { current_dues } from '../../../helper/dues_calculators'
import { ViewUserModal } from '../AllUser/ViewUserModal'
import ImposeFeeModal from './ImposeFeeModal'
import { useNavigate } from 'react-router-dom'
import { FeeButton } from './FeeButton'

function DueFees() {
    const [tableData,setTableData] = useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(()=>{
        setTableLoading(true);
        fee.dueslist().then(res=>{
            setTableData(res.data.dues);
            setTableLoading(false);
        }).catch(e=>console.log(e));
    },[])
    const columns = useMemo(()=>[
        {
            Header: "Student",
            Cell:(cell)=> {
                const imageUrl = ASSET_URL + cell.row.original.avatar;
                return (<span> <img className="me-2 rounded-circle header-profile-user" style={{objectFit:'cover'}} src={imageUrl} alt="User Avatar" />{cell.row.original.first_name} {cell.row.original.last_name}</span>)
            }
        },
        {
            Header: "st_name",
            DataClass:'text-center d-none',
            HeaderClass:'text-center d-none',
            accessor: "first_name",
        },
        {
            Header: "Class",
            DataClass:'text-center',
            HeaderClass:'text-center',
            Cell:(cell)=> {
                const row = cell.row.original;
                return row.courses.map(c=><span className='mx-2'>{c.name}</span>)
            }
        },
        {
            Header: "Phone",
            DataClass:'text-center',
            HeaderClass:'text-center',
            accessor: "phone",
        },
        {
            Header: "Total Dues",
            DataClass:'text-center bg-soft-warning',
            HeaderClass:'text-center bg-soft-warning',
            Cell:(cell)=> {
                const row = cell.row.original;
                return <span className='mx-2'>{current_dues(row)}</span>
            }
        },
        {
            Header: "Action",
            DataClass:'text-center',
            HeaderClass:'text-center',
            Cell:(cell)=> {
                const row = cell.row.original;
                return (
                    <>
                        <FeesPaymentModal row_data={row} setDuesData={setTableData} />
                        <button onClick={e=>handleNavigateToPrintDemandBill(row.id)} className='btn btn-sm btn-soft-warning me-1'><i className="ri-mail-send-fill fs-5" /></button>
                        <FeeButton id={row.id} />
                        {/* <DuesDescriptionModal row_data={row} /> */}
                        {/* <button className="btn btn-sm btn-soft-danger me-1" ><i className="ri-delete-bin-fill" /></button> */}
                    </>
                )
            }
        },
        {
            Header: "List",
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                const imageUrl = ASSET_URL+row.avatar;
                return (
                    <div className="d-flex">
                    <img className="me-2 rounded-circle header-profile-user" src={imageUrl} alt="User Avatar" />
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#" className="link text-dark"></a>
                            <a href="#">{row.first_name} {row.last_name}</a>
                        </h5>
                        <p className="text-muted mb-0">
                            <a href={`tel:${row.phone}`}><i className='bx bx-phone text-success' />{row.phone}</a>
                            <span className='badge badge-soft-warning mx-2' >Dues : {current_dues(row)}</span>
                        </p>
                        

                    </div>
                    <div className="flex-shrink-0">
                        <div>
                            <FeesPaymentModal row_data={row} setDuesData={setTableData} />
                            {/* <DuesDescriptionModal row_data={row} /> */}
                            <button onClick={e=>handleNavigateToPrintDemandBill(row.id)} className='btn btn-sm btn-soft-warning'><i className="ri-mail-send-fill fs-5" /></button>
                        </div>
                    </div>
                </div>
                )
            }
        },
    ]);
    const handleNavigateToPrintDemandBill = id => navigate('/demand-bill-print/'+id);
    return (
        <>
            <BreadCrumb title="Dues List" prevPage="Home" prevPath="/dashboard" />  
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Dues List</h5>
                            <ImposeFeeModal />
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isLoading={tableLoading} columns={columns} data={tableData} noRecord="No Records in Dues List" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default DueFees