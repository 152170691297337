export const blue       = "#3577f1";
export const indigo     = "#405189";
export const purple     = "#6559cc";
export const pink       = "#f672a7";
export const red        = "#f06548";
export const orange     = "#f1963b";
export const yellow     = "#f7b84b";
export const green      = "#0ab39c";
export const teal       = "#02a8b5";
export const cyan       = "#299cdb";
export const white      = "#ffffff";
export const gray       = "#878a99";
export const primary    = "#405189";
export const secondary  = "#3577f1";
export const success    = "#0ab39c";
export const info       = "#299cdb";
export const warning    = "#f7b84b";
export const danger     = "#f06548";
export const light      = "#f3f6f9";
export const gray_dark  = "#343a40";
export const gray_100   = "#f3f6f9";
export const gray_200   = "#eff2f7";
export const gray_300   = "#e9ebec";
export const gray_400   = "#ced4da";
export const gray_500   = "#adb5bd";
export const gray_600   = "#878a99";
export const gray_700   = "#495057";
export const gray_800   = "#343a40";
export const gray_900   = "#212529";

export const soft_danger    = "#f065482e";
export const soft_success   = "#0ab39c2e";
export const soft_warning   = "#f7b84b2e";
export const soft_primary   = "#4051892e";
export const soft_info      = "#299cdb2e";