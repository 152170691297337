import React from 'react'
import { Modal} from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { swal } from '../../../helper/swal';
import {enquiry} from '../../../helper/api_url'
import {useModalHandler} from '../../../helper/custom_hook';
export function FollowUpBtn(data) {
    const dispatch = useDispatch();
    const{status,toggleModal} = useModalHandler();
    const handleSubmit = e => {
        e.preventDefault();
        dispatch(setPreloader({ loader: true, message: 'Following please wait' }));
        const formData = new FormData(e.target); 
        enquiry.addFollow(formData)
            .then(res => {
                toggleModal(); 
                swal.success(res.data.message);
                if(data.onSuccess)data.onSuccess(res.data.followup);
            })
            .catch(err => swal.error(err.response ? err.response.data.message : err.message))
            .finally(()=>dispatch(setPreloader({ loader: false, message: '' })))
    }
    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-info add-btn waves-effect'>
                <i className="ri-add-line align-bottom me-1"></i>Follow
            </button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Follow To {data.follow.enquiry.name}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={e => handleSubmit(e)}>
                        <div className="row g-3">
                            <input type="hidden" name="enquiry_id" defaultValue={data.follow.enquiry.id} />
                            <div className="col-12">
                                <div>
                                    <label htmlFor="prevremark" className="form-label">Previous Remark</label>
                                    <textarea id="prevremark" className="form-control" defaultValue={data.follow.remark} rows="" readOnly></textarea>
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="joining_date" className="form-label">Join Date</label>
                                    <input type="date" className="form-control" id='joining_date' name="joining_date" defaultValue={data.follow.joining_date} />
                                </div>
                            </div>

                            <div className="col-6">
                                <div>
                                    <label htmlFor="status" className="form-label">Status</label>
                                    <select className='form-control' name='status'>
                                        {data.follow.status && <option defaultValue={data.follow.status}>{data.follow.status}</option>}
                                        <option value="pending">pending</option>
                                        <option value="deleted">Not intrested</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <div>
                                    <label htmlFor="remark" className="form-label">Remark</label>
                                    <textarea name="remark" id="remark" className="form-control" defaultValue="" rows="3"></textarea>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-4">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                    <button type="submit" className="btn btn-primary">Follow</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}