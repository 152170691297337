import LZString from 'lz-string';

const viewMarkSheet = (url,params={}) => {
    const token = LZString.compressToEncodedURIComponent(localStorage.getItem("_token"));
    const schoolCode = encodeURIComponent(window.__school_code);
    url = `${url}?&s=${schoolCode}&t=${token}`;
    Object.keys(params).forEach(key => {
        if(params[key]) url += `&${key}=${params[key]}`;
    });
    window.open(url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=800,height=600');
}

export const viewMarkSheetByClass = (course_id) => {
    let url = `https://management.unboxschool.in/printer/marksheet/bulk-marksheet-gurukul.php`;
    viewMarkSheet(url,{c:course_id});
}