import React, { useEffect, useMemo, useState } from 'react'
import BreadCrumb from '../../../components/common/BreadCrumb';
import { Card, CardBody, CardHeader, Col, Row, Button } from 'react-bootstrap';
import { TableResponsive } from '../../../components/common/TableResponsive';
import { useDispatch } from 'react-redux';
import { followup,ASSET_URL } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import {ViewFollowModal } from './ViewFollowModal';
import { FollowUpBtn} from './FollowUpBtn';
import { isDate1BeforeOrEqual, formatDate } from '../../../helper/formatDate';
const FollowUp = () => {
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const [tableLoading,setTableLoading]= useState(true);
    let currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`
    const [selectedDate, setSelectedDate] = useState(formattedDate);
    useEffect(() => {
        followup.pending(selectedDate)
        .then(r => setTableData(r.data.followup))
        .catch(err => swal.error(err.response ? err.response.data.message : err.message))
        .finally(()=>setTableLoading(false))
    }, [])
    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: "name",
            HeaderClass: '',
            DataClass: '',
            Cell: (cell) => {
                const row = cell.row.original;
                const trial_end = row.enquiry.tryal_date != null ? new Date(row.enquiry.tryal_date) : null;
                if (trial_end != null) trial_end.setDate(trial_end.getDate() + row.enquiry.tryal_period);
                const imageUrl = ASSET_URL + row.enquiry.avatar;
                return (<span> <img style={{objectFit:'cover'}} className="me-2 rounded-circle header-profile-user" src={imageUrl} alt="User Avatar" />
                    {row.enquiry.name}
                    {(trial_end != null)
                        ? (trial_end > Date.now()
                            ? (<span className='badge badge-soft-success px-1 ms-1'>On Trail</span>)
                            : (<span className='badge badge-soft-danger px-1 ms-1'>Trail Expired</span>))
                        : null}
                </span>)
            }
        },
        { Header: 'Class', accessor: 'enquiry.course.code' },
        { Header: 'Phone', accessor: 'enquiry.phone' },
        {
            Header: "Follow By",
            Cell: (cell) => {
                const row = cell.row.original;
                return (<span>{row.enquiry?.created_by?.first_name} {row.enquiry?.created_by?.last_name}</span>)
            }
        },
        {
            Header: "Status",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (<><span className='badge badge-soft-warning px-1 ms-1'>{row.status}...</span><br />{formatDate(row.joining_date)}</>)
            }
        },
        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <div className="">
                        <ViewFollowModal follow={row} />
                        {(isDate1BeforeOrEqual(formattedDate, row.joining_date)) ? <FollowUpBtn follow={row} /> : <span className='btn btn-sm btn-soft-warning add-btn waves-effect align-bottom me-1'>PostPond</span>}
                    </div>
                )
            },
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (followup) => {
                const row = followup;
                const imageUrl = ASSET_URL + row.enquiry.avatar;
                const trial_end = row.enquiry.tryal_date != null ? new Date(row.enquiry.tryal_date) : null;
                if (trial_end != null) trial_end.setDate(trial_end.getDate() + row.enquiry.tryal_period);
                return (
                    <div className="d-flex">
                        <ViewFollowModal follow={row}>
                            <img className="me-2 rounded-circle header-profile-user" src={imageUrl} alt="User Avatar" />
                        </ViewFollowModal>
                        <div className="flex-grow-1" data-id="1">
                            <h5 className="fs-13 mb-1">
                                <a href="#" className="link text-dark"></a>
                                <a href="#">{row.enquiry.name}</a>
                                {(trial_end != null)
                                    ? (trial_end > Date.now()
                                        ? (<span className='badge badge-soft-success px-1 ms-1'>On- Trail</span>)
                                        : (<span className='badge badge-soft-danger px-1 ms-1'>Trail Expired</span>))
                                    : null}
                            </h5>
                            <p className="text-muted mb-0">
                                <a href={`tel:${row.enquiry.phone}`}>
                                    <i className='bx bx-phone text-success' />{row.enquiry.phone}</a> | Applied for : {row.enquiry.course.code}
                            </p>
                        </div>
                        <div className="flex-shrink-0">
                            <div className="">
                                {(isDate1BeforeOrEqual(formattedDate, row.joining_date)) ? <FollowUpBtn follow={row} /> : <span className='badge badge-soft-warning px-1 ms-1'>PostPond</span>}
                            </div>
                        </div>
                    </div>
                )
            }
        }

    ]);
    return (
        <>
            <BreadCrumb title="Follow UP" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Follow Up List</h5>
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isLoading={tableLoading} columns={columns} data={tableData} noRecord="No Record Follow List" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}


export default FollowUp;
