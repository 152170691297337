
import Profile from "../pages/Profile/Profile"
import DueFees from "../pages/admin/Accounts/DueFees"
import InvoiceList from "../pages/admin/Accounts/InvoiceList"
import ViewInvoice from "../pages/admin/Accounts/ViewInvoice"
import { AddmissionList } from "../pages/admin/Admission/AddmissionList"
import Admission from "../pages/admin/Admission/Admission"
import AllUsers from "../pages/admin/AllUser/AllUsers"
import Staffs from "../pages/admin/AllUser/Staffs"
import Students from "../pages/admin/AllUser/Students"
import Visitors from "../pages/admin/AllUser/Visitors"
import { Attendance } from "../pages/admin/Attendance/Attendance"
import { AttendanceList } from "../pages/admin/Attendance/AttendanceList"
import Course from "../pages/admin/Course/Course"
import CourseProfile from "../pages/admin/Course/CourseProfile"
import Enquiry from "../pages/admin/Enquiry/Enquiry"
import EnquiryHistroy from "../pages/admin/Enquiry/EnquiryHistroy"
import FollowUp from "../pages/admin/Enquiry/FollowUp"
import Fees from "../pages/admin/Fees/Fees"
import { CreateReport } from "../pages/admin/ReportMangement/CreateReport"
import Role from "../pages/admin/Role/Role"
import ForgotPassword from "../pages/auth/ForgotPassword"
import Login from "../pages/auth/Login"
import Home from "../pages/admin/Dashboard/index"
import Locked from "../pages/public/Locked"
import Issues from "../pages/public/Support/Issues"
import IDCard from "../pages/student/IDCard"
import SetExaminationPapers from "../pages/admin/Examination/SetExaminationPapers"
import AdmitCardPrintable from "../pages/admin/Examination/printables/AdmitCardPrintable"
import DemandLetter from "../pages/admin/Examination/printables/DemandLetterPrint"
import PrintQuestionPaper from "../pages/admin/Examination/PrintQuestionPaper"
import HomeWorkClassWise from "../pages/admin/Course/homeworkclasswise"
import { HomeWorkList } from "../pages/admin/Course/HomeWorkList"
import { StudentDuesFees } from "../pages/admin/Accounts/StudentDuesFees"
import DemandBill from "../pages/admin/Communication/DemandBill"
import ClassAdmitCard from "../pages/admin/Course/ClassAdmitCard"
import FamilyDues from "../pages/admin/Accounts/FamilyDues/FamilyDues"
import CreateMarksheet from "../pages/admin/Examination/Temp/CreateMarksheet"
import ClassDemandBill from "../pages/admin/Communication/ClassDemandBill"
import Family from "../pages/admin/family"

export const authenticatedRoutes = [
    //Menu
    {label:'Set Examination',path:'/set-examination',element:<SetExaminationPapers />,users:['admin'],children:[]},
    {label:'Print Paper',path:'/print-question-paper',element:<PrintQuestionPaper />,users:['admin'],children:[]},
    {label:'Invoice',path:'/view-invoice',element:<ViewInvoice/>,users:['admin','staff','student'],children:[]},

    {label:'Report',path:'/report',element:<EnquiryHistroy/>,users:['admin','staff'],children:[]},
    {label:'newreport',path:'/createreport',element:<CreateReport/>,users:['admin'],children:[]},
    {label:'Admission',path:'/admission/new',element:<Admission />,users:['admin','staff'],children:[]},
    {type:'module',label:'Accounts',icon:' ri-coins-line',users:['admin','staff'],children:[
        {label:'Dues List',path:'/dues-list',element:<DueFees/>,users:['admin']},
        {label:'Family Dues',path:'/family-dues',element:<FamilyDues />,users:['admin']},
        {label:'Invoices',path:'/invoice-list',element:<InvoiceList/>,users:['admin']},
        {label:'Fees',path:'/fees',element:<Fees />,users:['admin']},
    ]}, 
    {path:'/student/:sid/fee',element:<StudentDuesFees/>,users:['admin',],children:[]},

    {label:'Profile',path:'/profile',element:<Profile />,users:['admin','staff','student'],children:[]},
    {path:'/attendance-list',element:<AttendanceList/>,users:['admin',],children:[]},
    {path:'/admit-card-print/:studentID',element:<AdmitCardPrintable/>,users:['admin',],children:[]},
    {path:'/demand-bill-print/:studentID',element:<DemandLetter/>,users:['admin',],children:[]},

    {label:'Idcard',path:'/idcard/:studentID',element:<IDCard />,users:['admin','staff','student'],children:[]},
    {label:'Class Profile',path:'/classprofile/:class_id',element:<CourseProfile />,users:['admin','staff','student'],children:[]},
    {label:'Class Admit Card',path:'/class-admitcard/:class_id',element:<ClassAdmitCard />,users:['admin','staff','student'],children:[]},
    
    {type:'menu',label:'Dashboard',path:'/dashboard',element:<Home/>,icon:'ri-home-8-line',users:['admin','staff','student'],children:[]},
    {type:'menu',label:'Admission',path:'/admission',element:<AddmissionList/>,icon:' ri-user-add-line',users:['admin',],children:[]},
    {type:'menu',label:'Class',path:'/classes',element:<Course />,icon:' ri-eye-fill',users:['admin','staff',],children:[]},
    {type:'menu',label:'User Management',path:'/about',icon:'ri-team-fill',users:['admin','staff'],children:[
        {label:'students',path:'/student',element:<Students />},
        {label:'staff',path:'/staff',element:<Staffs />},
        {label:'All Users',path:'/users',element:<AllUsers />,users:['admin']},
        {label:'Visitor',path:'/visitor',element:<Visitors />},
    ]},
    {type:'menu',label:'Family',path:'/family',element:<Family />,icon:'ri-user-star-fill',users:['admin'],children:[]},
    {type:'menu',label:'Attendance',path:'/attendance',element:<Attendance/>,icon:' bx bx-user-check',users:['admin','staff'],children:[]},
    {type:'menu',label:'Communication',path:'/communication',element:<Attendance/>,icon:' ri-mail-send-fill',users:['admin','staff'],children:[
        {label:'Family Demand Bill',path:'/family-demand-bill',element:<DemandBill />,users:['admin']},
        {label:'Class Demand Bill',path:'/class-demand-bill',element:<ClassDemandBill />,users:['admin']},
    ]},
    {path:'/attendance-list',element:<AttendanceList/>,users:['admin',],children:[]},
    
      

    {type:'menu',label:'Homeworks',path:'/home-work',element:<HomeWorkClassWise/>,icon:'  ri-quill-pen-fill',users:['admin','staff'],children:[]},
    {path:'/home-work/:classId',element:<HomeWorkList/>,icon:' bx bx-user-check',users:['admin','staff'],children:[]},
    {type:'menu',label:'Annoucements',path:'/services',element:<Locked />,icon:' bx bxs-megaphone',users:['admin'],children:[]},
    {type:'menu',label:'Exam Management',icon:' ri-survey-line',users:['admin'],children:[
        {label:'Marksheet',path:'/marksheet',element:<CreateMarksheet />,users:['admin']},
    ]},
    

    
    {type:'module',label:'Enquiry',path:'/about',icon:'ri-questionnaire-fill',users:['admin','staff'],children:[
        {label:'Enquiry',path:'/enquiry',element:<Enquiry />},
        {label:'Follow Up',path:'/followup',element:<FollowUp />}, 
    ]},
    
    
    // Modules
    
    {type:'module',label:'Support',path:'/support',element:<Issues />,icon:' ri-customer-service-2-fill',users:['admin',],children:[]},
    {type:'setting',label:'Setting',icon:'bx bx-cog',users:['admin'],children:[
        {label:'Role',path:'/role',element:<Role />},
        {label:'Personalization',path:'/persolization',element:<Home/>}
    ]},
]
export const publicRoutes = [
    {label:'Home',path:'/',element:<Login /> ,icon:'null',users:[],children:[]},

]
export const authRoutes = [
    {label:'Login',path:'/',element:<Login /> ,icon:'null',users:[],children:[]},
    {label:'Login',path:'/login',element:<Login /> ,icon:'null',users:[],children:[]},
    {label:'Password Reset',path:'/passwordReset',element:<ForgotPassword /> ,icon:'null',users:[],children:[]},
]