import React from 'react'
import { useNavigate } from 'react-router-dom';

function ViewInvoiceButton({id}) {
    const navigate = useNavigate();
    const handleViewInvoice = (id) => {
        navigate('/view-invoice', { state: { invoiceId: id } });
    }
    return (
        <button onClick={(e) => handleViewInvoice(id)} className='btn btn-sm btn-soft-info me-1'> <i className="ri-eye-fill" /> </button>
    )
}

export default ViewInvoiceButton