import React, { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { invoice } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { dateTimeSaparator } from '../../../helper/formatDate';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import "./printInvoice.css";

function ViewInvoice() {
    const { state } = useLocation();
    const [invoicData, setInvoiceData] = useState(null);
    const invoiceId = state && state.invoiceId;
    const appLogo = useSelector(state => state.auth.LOGO);
    const client = useSelector(state => state.auth.client);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useState(() => {
        if (invoiceId)
            invoice.read(invoiceId)
                .then(res => setInvoiceData(res.data.invoices))
                .catch(e => swal.error('Ooopss ! ', e.response ? e.response.data.message : e.message))
    }, [invoiceId])
    return (
        <>
            <div ref={componentRef} id='printable-area-st' className="row justify-content-center">
                <div className="col-xxl-9">
                    <div className="card" id="demo">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-header border-bottom-dashed p-4 ">
                                    <div className="d-flex position-relative">
                                        <div className="flex-grow-1">
                                            <img src={appLogo || "https://unboxschool.in/assets/images/logo.unbox.png"} className="card-logo card-logo-dark rounded" alt="logo dark" style={{
                                                aspectRatio: '1/1',
                                                objectFit: 'cover',
                                                width: '112px'
                                            }} />
                                            <img src={appLogo || "https://unboxschool.in/assets/images/logo.unbox.png"} className="card-logo card-logo-light rounded" alt="logo light" style={{
                                                aspectRatio: '1/1',
                                                objectFit: 'cover',
                                                width: '112px'
                                            }} />
                                            <div className='d-flex justify-content-start align-items-center flex-column w-100 position-absolute'>
                                                <h1>{client?.business_name || 'School Name'}</h1>
                                            </div>
                                            {client ? (
                                                <div className="mt-sm-5 mt-4">
                                                    <h6 className="text-muted text-uppercase fw-semibold">Address</h6>
                                                    <p className="text-muted mb-1" id="address-details">{client.business_address} {client.business_city}</p>
                                                    <p className="text-muted mb-1" id="address-details">{client.business_district} {client.business_state} {client.business_pin}</p>
                                                </div>
                                            ) : (
                                                <div className="mt-sm-5 mt-4">
                                                    <h6 className="text-muted text-uppercase fw-semibold">Address</h6>
                                                    <p className="text-muted mb-1" id="address-details">School Address dummy Address</p>
                                                    <p className="text-muted mb-0" id="zip-code"><span>Pin-code:</span> 110092</p>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex-shrink-0 mt-sm-0 mt-3">
                                            <h6><span className="text-muted fw-normal">School Code : </span><span id="legal-register-no">#{client?.client_code || '0000'}</span></h6>
                                            <h6><span className="text-muted fw-normal">Email: </span><span id="email">{client?.business_email || "support@unboxschool.com"}</span></h6>
                                            <h6>
                                                <span className="text-muted fw-normal">Website: </span>
                                                <a href={"https://" + (client?.subdomain ? client.subdomain + '.' : '') + "unboxschool.in/"} className="link-primary" target="_blank" id="website">
                                                    {(client?.subdomain ? client.subdomain : 'www') + ".unboxschool.in/"}
                                                </a>
                                            </h6>
                                            <h6 className="mb-0"><span className="text-muted fw-normal">
                                                Contact No: </span><span id="contact-no">+(91) {client?.business_phone || `93434 46789`} </span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-12">
                                <div className="card-body p-4">
                                    <div className="row g-3">
                                        <div className=" col-4">
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Invoice No</p>
                                            <h5 className="fs-14 mb-0">#UBXIN<span id="invoice-no">{invoicData?.id}</span></h5>
                                        </div>

                                        <div className="col-4">
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Date</p>
                                            <h5 className="fs-14 mb-0"><span id="invoice-date">{dateTimeSaparator(invoicData?.created_at).date}</span> <small className="text-muted" id="invoice-time">{dateTimeSaparator(invoicData?.created_at).time}</small></h5>
                                        </div>

                                        <div className="col-4">
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Total Amount</p>
                                            <h5 className="fs-14 mb-0">&#8377;<span id="total-amount"> {invoicData?.total_amount - (invoicData?.discount || 0)}</span></h5>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div className="col-lg-12">
                                <div className="card-body p-4 border-top border-top-dashed">
                                    <div className="row g-3">
                                        <div className="col-6">
                                            <h6 className="text-muted text-uppercase fw-semibold mb-3">User Details</h6>
                                            <p className="fw-medium mb-2" id="billing-name">{invoicData?.payer_name}</p>
                                            <p className="text-muted mb-1" id="billing-address-line-1">{invoicData?.payer_email}</p>
                                            <p className="text-muted mb-1"><span>Phone: +91 </span><span id="billing-phone-no">{invoicData?.payer_phone}</span></p>
                                            <p className="text-muted mb-0"><span>User Id : </span><span id="billing-tax-no"> #{invoicData?.payer?.id}</span> </p>
                                        </div>

                                        <div className="col-6">
                                            <h6 className="text-muted text-uppercase fw-semibold mb-3">Receiver Details</h6>
                                            <p className="fw-medium mb-2" id="billing-name">{invoicData?.receiver_name}</p>
                                            <p className="text-muted mb-1" id="billing-address-line-1">{invoicData?.receiver_email}</p>
                                            <p className="text-muted mb-1"><span>Phone: +91 </span><span id="billing-phone-no">{invoicData?.receiver_phone}</span></p>
                                            <p className="text-muted mb-0"><span>User Id : </span><span id="billing-tax-no"> #{invoicData?.receiver?.id}</span> </p>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div className="col-lg-12">
                                <div className="card-body p-4">
                                    <div className="table-responsive">
                                        <table className="table table-borderless table-nowrap align-middle mb-0">
                                            <thead>
                                                <tr className="table-active">
                                                    <th scope="col" style={{ width: '50px' }}>#</th>
                                                    <th scope="col">Perticulars</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col" className="text-end">Paid Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody id="products-list">
                                                {
                                                    invoicData?.transactions?.map((transaction, index) => (
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td className="text-start">
                                                                <span className="fw-medium">{transaction.name}</span>
                                                                <p className="text-muted mb-0">{transaction.description}</p>
                                                            </td>
                                                            <td>&#8377; {transaction?.amount}</td>
                                                           
                                                            <td className="text-end">&#8377; {transaction?.amount}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="border-top border-top-dashed mt-2">
                                        <table className="table table-borderless table-nowrap align-middle mb-0 ms-auto" style={{ width: "250px" }}>
                                            <tbody>
                                                <tr>
                                                    <td>Sub Total</td>
                                                    <td className="text-end">&#8377; {invoicData?.total_amount}</td>
                                                </tr>
                                                <tr>
                                                    <td>Discount <small className="text-muted">(--)</small></td>
                                                    <td className="text-end">-&#8377; {(invoicData?.discount || 0)}</td>
                                                </tr>
                                                <tr className="border-top border-top-dashed fs-15">
                                                    <th scope="row">Total Amount</th>
                                                    <th className="text-end">&#8377; {invoicData?.total_amount - (invoicData?.discount || 0)}</th>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="mt-3">
                                        <h6 className="text-muted text-uppercase fw-semibold mb-3">Payment Details:</h6>
                                        <p className="text-muted mb-1">Payment Method: <span className="fw-medium" id="payment-method">{invoicData?.payment_mode}</span></p>
                                        <p className="text-muted">Total Amount: <span className="fw-medium" id="">$ </span><span id="card-total-amount">&#8377; {invoicData?.total_amount}</span></p>
                                    </div>

                                    <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                        <button onClick={handlePrint} className="btn btn-success"><i className="ri-printer-line align-bottom me-1"></i> Print</button>
                                        {/* <a href="javascript:void(0);" className="btn btn-primary"><i className="ri-download-2-line align-bottom me-1"></i> Download</a> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ViewInvoice