import axios from "axios";
// import { APIURL } from "../config";
import Cookies from 'js-cookie';

export const getApiUrl = () => {
  const apiUrl = Cookies.get('api_url');
  if (!apiUrl) {
    console.log('API URL is not set in the cookies.');
  }
  return apiUrl;
};
export const API_URL = getApiUrl();

const axiosApi = axios.create({
  baseURL: getApiUrl(),
});
const setToken = () => {
  const token = localStorage.getItem("_token");
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  setToken();
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  setToken();
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  setToken();
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  setToken();
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
