import React, { useEffect, useMemo, useState } from 'react';
import { TableResponsive } from '../../../components/common/TableResponsive';
import { homework } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { Button, Card } from 'react-bootstrap';
import { formatDate } from '../../../helper/formatDate';
import Swal from 'sweetalert2';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

export const HomeWorkList = ({ classId ,activeTab=false}) => {
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        homework.list(classId)
            .then(r => setTableData(r.data.homework))
            .catch(err => swal.error(err.response ? err.response.data.message : err.message));
    }, [classId]);

    const handlehomeworkdelete = (homeId, course_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this homework?",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: "Delete",
            denyButtonText: `No`
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setPreloader({ loader: true, message: 'Deleting, please wait...' }));
                homework.delete(homeId, course_id)
                    .then(res => {
                        console.log(res.data)
                        setTableData(tableData.filter(td => td.id !== homeId)); // Filter by homework ID
                        swal.success(res.message);
                    })
                    .catch(err => swal.error(err.response ? err.response.data.message : err.message))
                    .finally(() => {
                        dispatch(setPreloader({ loader: false, message: "" }));
                    });
            }
        });
    };

    const columns = useMemo(() => [
        {
            Header: "SrNo",
            accessor: "id",
            Cell: cell => <p>{cell.row.index + 1}</p>,
            HeaderClass: 'text-center',
            DataClass: 'text-center'
        },
        {
            Header: "Subject",
            accessor: "questions[0].subject",
            HeaderClass: 'text-center',
            DataClass: 'text-center'
        },
        {
            Header: "Question",
            Cell: ({ row }) => {
                const questions = row.original.questions;
                return (
                    <>
                        {questions.map((item, idx) => (
                            <p key={idx}>{item.question}</p>
                        ))}
                    </>
                );
            },
            HeaderClass: '',
            DataClass: '',
        },
        {
            Header: "Title",
            accessor: "title",
            HeaderClass: 'text-center',
            DataClass: 'text-center'
        },
        {
            Header: "Description",
            accessor: "description",
            HeaderClass: 'text-center',
            DataClass: 'text-center'
        },
        {
            Header: "Submission Date",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: cell => (
                <span>{formatDate(cell.row.original.submission_date)}</span>
            )
        },
        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: cell => {
                const row = cell.row.original;
                return (
                    <Button
                        className="btn btn-sm btn-soft-danger me-1"
                        onClick={() => handlehomeworkdelete(row.id, row.course_id)}
                    >
                        <i className="ri-delete-bin-fill" />
                    </Button>
                );
            },
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                const bs_colors = ['primary', 'info', 'warning', 'danger', 'success', 'light', 'secondary'];
                return (
                    <div className="d-flex">
                        <div className="flex-grow-1" data-id="1">
                            <h5 className="fs-13 mb-1">
                                <a href="#" className="link text-dark"></a>
                                <span className={`badge bg-info px-2  mb-2 mx-2`} >{row.title}</span> |
                                <span className={`badge bg-success px-2  mb-2 mx-2`} >{row.questions[0].subject}</span>
                                <a href="#" className='pt-2'>
                                    {row.questions.map((item, idx) => (
                                        <p key={idx}>{item.question}</p>
                                    ))}
                                </a>
                            </h5>
                            <p className="text-muted mb-0">
                                {row.description} | {formatDate(row.submission_date)}</p>
                        </div>
                        <div className="flex-shrink-0">
                            <Button
                                className="btn btn-sm btn-soft-danger me-1"
                                onClick={() => handlehomeworkdelete(row.id, row.course_id)}
                            >
                                <i className="ri-delete-bin-fill" />
                            </Button>
                        </div>
                    </div>
                )
            }
        }

    ], [tableData]);

    return (
        <>
            <Card className='mt-3'>
                <div className='mx-3 my-3'>
                    {activeTab!==true?<Link to="/home-work" className='btn btn-soft-dark add-btn waves-effect me-2 text-end'><i className="ri-contacts-fill"></i></Link>:''}
                    <TableResponsive columns={columns} data={tableData} />
                </div>
            </Card>
        </>
    );
};
