import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

function PrintQuestionPaper({data}) {
    const { state } = useLocation();
    const navigate = useNavigate();
    const questions = state && state.data;
    const handleNavigateBack = e => navigate('/set-examination');
    return (
        <div>
            <h2 className='text-center'>Print This Data</h2>
            {questions?.map(q=>(<h5 key={q.id}>{q.question}</h5>))}
            <button onClick={handleNavigateBack} className='btn btn-primary'> {"<<"} Back</button>
        </div>
    )
}

export default PrintQuestionPaper