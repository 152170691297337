import React, { useEffect, useMemo, useState } from 'react'
import { Badge, Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Row } from 'react-bootstrap'
import CustomSelect from '../../../components/CustomSelect'
import { course, family } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { TableResponsive } from '../../../components/common/TableResponsive';
import { useQuery } from '@tanstack/react-query';
import { MONTHS } from '../../../helper/constants';
import { ViewCourseModal } from '../Course/ViewCourseModal';
import { handleDemandBillViewByClass } from '../../../helper/print-demand-bill';


const ClassDemandBill = () => {
    const currentDate = new Date();
    const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth());
    const { data: coursList = [], isLoading, isError, error } = useQuery({
        queryKey: ['courses'],
        queryFn: () => course.list(),
        select: (data) => data.data.course,
        staleTime: 20 * 60 * 1000,
        gcTime: 20 * 60 * 1000,
    });

    useEffect(() => {
        if (isError) swal.error(error.response ? error.response.data.message : error.message)
    }, [isError, error])
    const columns = useMemo(() => [
        { Header: "Class Name", accessor: "name" },
        { Header: "Code", accessor: "code", HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: "Type", accessor: "type", HeaderClass: 'text-center', DataClass: 'text-center' },        
        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                return (
                    <div className="">
                        <button
                            className='btn btn-sm btn-primary'
                            onClick={() => handleDemandBillViewByClass(selectedMonth,document.querySelector('#upi-id').value,cell.row.original.id)}
                        >
                            Print Demand Bill
                        </button>
                    </div>
                )
            },
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                const bs_colors = ['primary', 'info', 'warning', 'danger', 'success', 'light', 'secondary'];
                const badge_class = row.id <= 6 ? `badge-outline-${bs_colors[row.id]}` : `badge-S-${bs_colors[row.id % 6]}`;
                return (
                    <div className="d-flex">
                        <div className="flex-grow-1" data-id="1">
                            <h5 className="fs-13 mb-1">
                                <a href="#" className="link text-dark"></a>
                                <ViewCourseModal view={row}>
                                    <a href="#">{row.name}</a>
                                </ViewCourseModal>
                                <span className={`badge ${badge_class} px-1 ms-1`} >{row.code}</span>
                            </h5>
                            <p className="text-muted mb-0">
                                {row.type} | For : {row.semesters} Semester</p>
                        </div>
                        <div className="flex-shrink-0">
                            <div>
                                <button
                                    className='btn btn-sm btn-primary'
                                    onClick={() => handleDemandBillViewByClass(selectedMonth,document.querySelector('#upi-id').value,row.id)}
                                >
                                    Print Demand Bill
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    ]);
    return (
        <>
            <Card>
                <CardHeader>
                    <CardTitle className='d-flex justify-content-between'>
                        <h3>Demand Bill By Class</h3>
                        <button className="btn btn-sm btn-primary px-2 py-0 d-flex align-items-center gap-1 font-13">
                            <i className="bx bx-cog m-0"></i> Settings
                        </button>
                    </CardTitle>
                    <CardSubtitle className='mt-3'> In this section, you can Generate, Send demand bill sms or Print Demand bill for students </CardSubtitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg={3} className="mb-2">
                            <CustomSelect
                                onChange={e => {
                                    setSelectedMonth(e.value);
                                }}
                                defaultValue={MONTHS.filter(i => i.value === currentDate.getMonth())}
                                options={MONTHS} placeholder="Select Month"
                            />
                        </Col>
                        <Col lg={3} className='mb-2'>
                            <input type="text" className='form-control' id='upi-id' placeholder='Enter UPI ID' />
                        </Col>
                        <Col lg={3} className="mb-2">
                            <button className='btn btn-soft-danger w-100 d-flex justify-content-center align-items-center'>
                                <i class="ri-mail-send-fill my-0 py-0 me-2"></i>
                                Send Demand Bill SMS
                            </button>
                        </Col>
                        <Col lg={3} className="mb-2">
                            <button
                                className='btn btn-soft-warning w-100 d-flex justify-content-center align-items-center'
                                onClick={() => handleDemandBillViewByClass(selectedMonth, document.querySelector('#upi-id').value, null)}
                            >
                                <i className="bx bxs-file-pdf my-0 py-0 me-2"></i>
                                Generate Demand Bill
                            </button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <TableResponsive
                        loading={isLoading}
                        isPagination={false}
                        isShowingPageLength={false}
                        customPageSize={1000}
                        showCustomOptionPage={false}
                        columns={columns}
                        data={coursList}
                        noRecord="No Record Course List"
                    />
                </CardBody>
            </Card>
        </>
    )
}

export default ClassDemandBill;
