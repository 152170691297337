import React, { useEffect, useMemo, useState } from 'react'
import BreadCrumb from "../../../components/common/BreadCrumb"
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Nav, Row } from 'react-bootstrap'
import { TableResponsive } from '../../../components/common/TableResponsive'
import { course, examination, invoice } from '../../../helper/api_url'
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import CustomSelect from '../../../components/CustomSelect'
import { SetQuestionsModal } from './Modals/ExamModals/SetQuestionsModal'
import { subjects } from '../../../helper/constants'

function SetExaminationPapers() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tableData,setTableData] = useState([]);
    const [courses,setCourses] = useState([]);
    const [exams,setExams] = useState([]);
    const handleDelete = (data) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete: " + data.course_name,
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: "Delete",
            denyButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) setTableData(s=>s.filter(q=>q.id !== data.id))
        });
    };
    const setMarks = (e,question)=>{
        if(e.target.value)
        setTableData(state =>
            state.map(s => {
                if (s.id === question.id) {
                    return { ...s, marks:e.target.value };
                }
                return s;
            })
        );
    }
    const handleNavigateToPrint = e => navigate('/print-question-paper',{state:{data:tableData}});
    useEffect(() => { 
        course.list().then(res=>setCourses(res.data.course)).catch(err=>console.log(err));
        examination.list().then(res=>setExams(res.data.exam)).catch(err=>console.log(err));
    }, [])

    const columns = useMemo((i=0) => [
        { Header: "SrNo", accessor: "id", Cell: cell => { return (<p className="">{cell.row.index + 1}</p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
        {
            Header: "Question",
            accessor: "question",
            Cell:cell=><div style={{minWidth:'550px'}}>{cell.row.original.question}</div>
        },
        {
            Header: "Marks",
            Cell:cell=>(<input 
                id={cell.row.original.marks}
                key={cell.row.original.marks}
                type='number' 
                step={0.1} 
                className='form-control' 
                value={cell.row.original.marks}
                onChange={e=>setMarks(e,cell.row.original)}
            />)
        },
        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                return (
                    <Button
                        onClick={() => handleDelete(cell.row.original)}
                        className="btn btn-sm btn-soft-danger me-1" >
                        <i className="ri-delete-bin-fill" />
                    </Button>
                )
            },
        }
    ]);

    return (
        <>
            <BreadCrumb title="Set Paper" prevPage="Examination" prevPath="/examination" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <Row className="w-100">
                                <Col xs={3} className="mb-2">
                                    <label htmlFor="" className="form-label">Select Examination</label>
                                    <CustomSelect 
                                        isSearchable 
                                        options={exams.map(e=>({label:e.name,value:e.id}))} 
                                        name="name" 
                                        placeholder="select exam" 
                                        className="w-100"
                                    />
                                </Col>
                                <Col xs={3} className="mb-2">
                                    <label htmlFor="" className="form-label">Select Class</label>
                                    <CustomSelect 
                                        isSearchable
                                        options={courses.map(c=>({label:c.name,value:c.id}))} 
                                        name="class_id" 
                                        placeholder="select subject" 
                                        className="w-100"
                                    />
                                </Col>
                                <Col xs={3} className="mb-2">
                                    <label htmlFor="" className="form-label">Select Subject</label>
                                    <CustomSelect 
                                        options={subjects}
                                        name="class_id"
                                        placeholder="select subject"
                                        className="w-100"
                                    />
                                </Col>
                                <Col xs={3} className="mb-2 d-flex align-items-end">
                                    <SetQuestionsModal courses={courses} data={tableData} setData={setTableData} />
                                </Col>
                                <Col xs={4} className="mb-2">
                                    <label htmlFor="paper_date" className="form-label">Paper Date</label>
                                    <input type='date' className='form-control'/>
                                </Col>
                                <Col xs={4} className="mb-2">
                                    <label htmlFor="paper_start_time" className="form-label">Paper Start Time</label>
                                    <input type='time' className='form-control'/>
                                </Col>
                                <Col xs={4} className="mb-2">
                                    <label htmlFor="paper_start_time" className="form-label">Paper End Time</label>
                                    <input type='time' className='form-control'/>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="position-relative">
                            <TableResponsive
                                customPageSize={100}
                                showCustomOptionPage={false}
                                showAddButton={true}
                                showFilter={false}
                                isLoading={false} columns={columns}
                                data={tableData}
                                isPagination={false}
                                noRecord="Click Add Question Button To Add Qustions. Your Questions will appear here."
                            />
                        </CardBody>
                        <CardFooter className='d-flex justify-content-end'>
                            <button disabled={!tableData.length} onClick={handleNavigateToPrint} className="btn btn-outline-warning  px-4 me-2">Print</button>
                            <Button disabled={!tableData.length} className="btn btn-primary px-4">Save</Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default SetExaminationPapers