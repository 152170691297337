export const priority = [
    {color:'danger',key:"Highest",value:100},
    {color:'danger',key:"High",value:90},
    {color:'warning',key:"Top Staff",value:80},
    {color:'warning',key:"Medium High" ,value:70},
    {color:'info',key:"Medium",value:60},
    {color:'primary',key:"Medium Low",value:50},
    {color:'primary',key:"Bottom Staff" ,value:40},
    {color:'success',key:"Low",value:30},
    {color:'muted',key:"Lowest",value:0}
];
const next_pervious = (number)=>{
    if(number <= 10) return {previous: 0 , next:10}
    let mod = number%10
    if(mod == 0) return {previous:number-10,next:number}
    return {previous:number-mod,next:(number+(10-mod))}
}
export const getPriorityValue = p => {
    if(p>100) return priority[0];
    if(next_pervious(p).next <=20) return priority[priority.length-1]
    return priority.find(role=>role.value === next_pervious(p).next)
}

export const subjects = [
    {label:'Science',value:'science',classId:10},
    {label:'Drawing',value:'drawing',classId:10},
    {label:'Social Science',value:'social_science',classId:10},
    {label:'Hindi',value:'hindi',classId:10},
    {label:'English',value:'english',classId:10},
    {label:'Mathematics',value:'mathematics',classId:10},
    
    {label:'Science',value:'science',classId:16},
    {label:'Drawing',value:'drawing',classId:16},
    {label:'Social Science',value:'social_science',classId:16},
    {label:'Hindi',value:'hindi',classId:16},
    {label:'English',value:'english',classId:16},
    {label:'Mathematics',value:'mathematics',classId:16},
    
    {label:'Science',value:'science',classId:8},
    {label:'Drawing',value:'drawing',classId:8},
    {label:'Social Science',value:'social_science',classId:8},
    {label:'Hindi',value:'hindi',classId:8},
    {label:'English',value:'english',classId:8},
    {label:'Mathematics',value:'mathematics',classId:8},
    
    {label:'Science',value:'science',classId:9},
    {label:'Drawing',value:'drawing',classId:9},
    {label:'Social Science',value:'social_science',classId:9},
    {label:'Hindi',value:'hindi',classId:9},
    {label:'English',value:'english',classId:9},
    {label:'Mathematics',value:'mathematics',classId:9},

    {label:'Mathematics',value:'mathematics',classId:11},
    {label:'Drawing',value:'drawing',classId:11},
    {label:'Hindi',value:'hindi',classId:11},
    {label:'English',value:'english',classId:11},
    {label:'Sanskrit',value:'Sanskrit',classId:11},
    {label:'Physics',value:'Physics',classId:11},
    {label:'Chemestry',value:'Chemestry',classId:11},
    {label:'Biology',value:'Biology',classId:11},
    {label:'Civics',value:'Civics',classId:11},
    {label:'Geography',value:'Geography',classId:11},
    {label:'History',value:'History',classId:11},
    
    {label:'Mathematics',value:'mathematics',classId:12},
    {label:'Drawing',value:'drawing',classId:12},
    {label:'Hindi',value:'hindi',classId:12},
    {label:'English',value:'english',classId:12},
    {label:'Sanskrit',value:'Sanskrit',classId:12},
    {label:'Physics',value:'Physics',classId:12},
    {label:'Chemestry',value:'Chemestry',classId:12},
    {label:'Biology',value:'Biology',classId:12},
    {label:'Civics',value:'Civics',classId:12},
    {label:'Geography',value:'Geography',classId:12},
    {label:'History',value:'History',classId:12},
    
    {label:'Mathematics',value:'mathematics',classId:13},
    {label:'Drawing',value:'drawing',classId:13},
    {label:'Hindi',value:'hindi',classId:13},
    {label:'English',value:'english',classId:13},
    {label:'Sanskrit',value:'Sanskrit',classId:13},
    {label:'Physics',value:'Physics',classId:13},
    {label:'Chemestry',value:'Chemestry',classId:13},
    {label:'Biology',value:'Biology',classId:13},
    {label:'Civics',value:'Civics',classId:13},
    {label:'Geography',value:'Geography',classId:13},
    {label:'History',value:'History',classId:13},
    
    {label:'Mathematics',value:'mathematics',classId:14},
    {label:'Drawing',value:'drawing',classId:14},
    {label:'Hindi',value:'hindi',classId:14},
    {label:'English',value:'english',classId:14},
    {label:'Sanskrit',value:'Sanskrit',classId:14},
    {label:'Physics',value:'Physics',classId:14},
    {label:'Chemestry',value:'Chemestry',classId:14},
    {label:'Biology',value:'Biology',classId:14},
    {label:'Civics',value:'Civics',classId:14},
    {label:'Geography',value:'Geography',classId:14},
    {label:'History',value:'History',classId:14},
];

export const MONTHS = [
    { days: '31', value: 0, label: "January" },
    { days: '28', value: 1, label: "February" },
    { days: '31', value: 2, label: "March" },
    { days: '30', value: 3, label: "April" },
    { days: '31', value: 4, label: "May" },
    { days: '30', value: 5, label: "June" },
    { days: '31', value: 6, label: "July" },
    { days: '31', value: 7, label: "August" },
    { days: '30', value: 8, label: "September" },
    { days: '31', value: 9, label: "October" },
    { days: '30', value: 10, label: "November" },
    { days: '31', value: 11, label: "December" }
];