
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { authenticatedRoutes, publicRoutes, authRoutes } from "./routes";
import './assets/css/bootstrap.min.css';
import './assets/css/icons.min.css';
import './assets/css/app.css';
import Layout from "./components/common/Layout/Layout";
import 'simplebar-react/dist/simplebar.min.css';
import Error404 from "./pages/Error/404";
import AuthLayout from "./components/Auth/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setLayout, changeSideBar, updateScreenSize } from "./features/Layout/layoutSlice";
import Preloader from "./components/Preloader";
import { AuthMiddleWare, GuestMiddleware } from "./routes/routes";
import { setPreloader } from "./features/Ui/uiSlice";
import axios from "axios";
import { initializeApp } from "./features/Auth/authSlice";
import Cookies from 'js-cookie';
import {
	QueryClient,
	QueryClientProvider,
	useQuery,
} from '@tanstack/react-query';
const queryClient = new QueryClient()

const layoutChanger = s => {
	localStorage.setItem('layoutConfig', JSON.stringify(s))
	document.documentElement.dataset.layoutMode = s.layoutMode;
	document.documentElement.dataset.sidebar = s.sidebar;
	document.documentElement.dataset.topbar = s.topbar;
	document.documentElement.dataset.sidebarSize = s.sidebarSize;// == "lg"?"sm-hover":s.sidebarSize;
	document.documentElement.dataset.preloader = s.preloader;
	document.documentElement.dataset.sidebarImage = "img-1";
	document.documentElement.dataset.layoutPosition = "fixed";
	document.documentElement.dataset.layoutStyle = "detached";
}
const screenSize = s => {
	let sizes = { sm: 768, md: 992, lg: 1200 }
	return s >= sizes.md ? 'lg' : (s < sizes.sm ? 'sm' : 'md');
}
const setBodyClass = s => {
	if (s === "lg" || s == "md") document.getElementsByTagName('body')[0].classList.remove('twocolumn-panel')
	else document.getElementsByTagName('body')[0].classList.add('twocolumn-panel')
}

function App() {
	const dispatch = useDispatch();
	const [initialized, setInitialized] = useState(false);
	const ENV = process.env.NODE_ENV || 'No environment set';
	useEffect(() => {
		dispatch(setPreloader({ loader: true, message: 'initializing app' }))
		const fetchClientConfig = async () => {
			const __SCHOOL_CODE = window.__school_code || '0000';
			const response = await axios.get(`https://management.unboxschool.in/backend/api/client-data/${__SCHOOL_CODE}`);
			return response.data;
		};
		fetchClientConfig().then(res => {
			const clientData = res.data.client;
			const domain = ENV === 'development' ? 'localhost' : clientData.subdomain + '.unboxschool.in';
			dispatch(initializeApp({
				business_name: clientData.business_name,
				api_url: 'https://' + clientData.api_url,
				asset_url: 'https://' + clientData.asset_url,
				subdomain: clientData.subdomain,
				logo: clientData.logo,
				client: res.data.client
			}))
			Cookies.set('business_name', clientData.business_name, { expires: 7, domain: domain });
			Cookies.set('api_url', 'https://' + clientData.api_url, { expires: 7, domain: domain });
			Cookies.set('asset_url', 'https://' + clientData.asset_url, { expires: 7, domain: domain });
			Cookies.set('subdomain', clientData.subdomain, { expires: 7, domain: domain });
			Cookies.set('logo', clientData.logo, { expires: 7, domain: domain });
			document.title = clientData.business_name || 'Default Title';
			setInitialized(true);
		}).catch(e => console.log(e.response ? e.response.date.message : e.message))
			.finally(() => dispatch(setPreloader({ loader: false, message: '' })))
	}, [dispatch]);
	const layoutStates = useSelector(state => state.layout);
	const handleResize = () => {
		const s = screenSize(window.innerWidth);
		setBodyClass(s);
		dispatch(updateScreenSize(s));
		if (s === 'md') dispatch(changeSideBar('sm'))
		if (s === 'sm') dispatch(changeSideBar('lg'))
		else dispatch(changeSideBar('sm-hover'))
	}
	useEffect(() => {
		let layoutconfiguraitons = localStorage.getItem('layoutConfig');
		if (layoutconfiguraitons && (layoutconfiguraitons !== JSON.stringify(layoutStates)))
			dispatch(setLayout(JSON.parse(layoutconfiguraitons)));
	}, [])
	useEffect(() => { layoutChanger(layoutStates); }, [layoutStates]);
	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => { window.removeEventListener('resize', handleResize); };
	}, [])

	const loader = useSelector(state => state.ui);
	return initialized ? (
		<QueryClientProvider client={queryClient}>
			{loader.loader ? (<Preloader title={loader.message} />) : null}
			<BrowserRouter>
				<Routes>
					{authRoutes.map((route, idx) => (<Route key={idx} path={route.path} element={(<GuestMiddleware><AuthLayout>{route.element}</AuthLayout></GuestMiddleware>)} />))}
					{publicRoutes.map((route, idx) => (<Route path={route.path} element={(<GuestMiddleware>{route.element}</GuestMiddleware>)} key={idx} />))}
					{authenticatedRoutes.map((route, idx) =>
						route.children.length
							? route.children.map((child, id) => (<Route path={child.path} element={(<AuthMiddleWare users={route.users}><Layout>{child.element}</Layout></AuthMiddleWare>)} key={id} />))
							: (<Route path={route.path} element={(<AuthMiddleWare users={route.users}><Layout>{route.element}</Layout></AuthMiddleWare>)} key={idx} />)
					)}
					<Route path="*" element={<AuthLayout><Error404 /></AuthLayout>} />
				</Routes>
			</BrowserRouter>
		</QueryClientProvider>
	) : <h1>please wait</h1>;
}

export default App;
