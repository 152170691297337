import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    _token: false,
    _user: {},
    _role: null,
    _roleType: null,
    SCHOOL_NAME: 'Unbox School',
    APIURL: 'https://demo.unboxschool.in/backend/api',
    HOMEPAGE: 'https://demo.unboxschool.in/',
    START_URL: 'https://demo.unboxschool.in/',
    ASSETURL: 'https://demo.unboxschool.in/backend/',
    LOGO: 'https://management.unboxschool.in/backend/assets/client/logo/TeY2CGAnhfvk5oXua0eyXTRRsPxOCBVYJyL7EAzs.png',
    client: null
}
const AuthSlice = createSlice({
    name: "Auth",
    initialState,
    reducers: {
        authenticate: (state, action) => {
            state._token = action.payload._token
            state._user = action.payload._user
            state._role = state._user.role ? state._user.role.id : null
            state._roleType = state._user.role ? state._user.role.type.toLowerCase() : null
        },
        initializeApp: (state, action) => ({
            ...state,
            APPNAME: action.payload.business_name,
            APIURL: action.payload.api_url,
            HOMEPAGE: action.payload.subdomain + '.unboxschool.in/',
            START_URL: action.payload.subdomain + '.unboxschool.in/',
            ASSETURL: action.payload.asset_url,
            LOGO: 'https://management.unboxschool.in/backend/' + action.payload.logo,
            client : action.payload.client
        })
    }
});

export const { authenticate, initializeApp } = AuthSlice.actions;
export default AuthSlice.reducer;