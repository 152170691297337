import React, { useEffect, useMemo, useState } from 'react'
import BreadCrumb from "../../../components/common/BreadCrumb";
import { Card, CardBody, CardHeader, Col, Row, Button } from "react-bootstrap";
import { TableResponsive } from "../../../components/common/TableResponsive";
import { ViewUserModal } from './ViewUserModal';
import { AllUserModal } from './AllUserModal';
import { UpdateAllUserModal } from './UpdateAllUserModal';
import { student, ASSET_URL } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { Switch } from 'antd';
import { usePageInitialtor } from '../../../helper/custom_hook';
import { FeeButton } from '../Accounts/FeeButton';
import ImposeFeeModal  from '../Accounts/ImposeFeeModal';
import ChangeFamilyModal from '../family/change-family-modal';
import ViewFeeDetailsModal from '../Accounts/view-fee-details-modal';

function Students() {
    const { tableData, setTableData, tableLoading, apiHandler } = usePageInitialtor(student);
    const { handleDelete } = apiHandler;
    useEffect(() => {
        student.list().then(r => setTableData(r.data.users)).catch(err => swal.error(err.response ? err.response.data.message : err.message))
    }, []);
    const handleUserStateChange = function (user) {
        setTableData([...tableData.map(u => (u.id === user.id ? { ...u, changing: true } : u))]);
        student.changeStatus(user.id).then(res => {
            setTableData([...tableData.map(u => (u.id === user.id ? { ...u, is_active: res.data.user.is_active } : u))]);
        }).catch(err => swal.error(err.response ? err.response.data.message : err.message));
    }
    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: "first_name",
            HeaderClass: '',
            DataClass: '',
            Cell: (cell) => {
                const imageUrl = ASSET_URL + cell.row.original.avatar;
                return (<span> <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={imageUrl} alt="User Avatar" />{cell.row.original.first_name} {cell.row.original.last_name}</span>)
            }
        },
        {
            Header: "Phone",
            accessor: "phone",
            HeaderClass: '',
            DataClass: '',
        },
        {
            Header: "Email",
            accessor: "email",
            HeaderClass: '',
            DataClass: '',
        },
        {
            Header: "User Type",
            accessor: "role.name",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
        },
        {
            Header: "Class",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: cell => {
                const row = cell.row.original;
                return row.courses[0]?.name
            }
        },
        {
            Header: "Status",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                return (<Switch
                    className={cell.row.original.is_active ? 'bg-primary' : ''}
                    loading={cell.row.original.changing ? true : false}
                    checkedChildren={(<span style={{ fontSize: "10px" }}>Active</span>)}
                    unCheckedChildren={(<span style={{ fontSize: "10px" }}>Inactive</span>)}
                    checked={cell.row.original.is_active}
                    onChange={() => handleUserStateChange(cell.row.original)}
                />)
            }
        },
        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <div className="">
                        <ViewUserModal data={cell.row.original} />
                        <UpdateAllUserModal data={cell.row.original} userType='Student' apiHandler={apiHandler} />
                        <Button onClick={() => handleDelete({ name: row.first_name, id: row.id })} className="btn btn-sm btn-soft-danger me-1" >
                            <i className="ri-delete-bin-fill" />
                        </Button>
                        <FeeButton id={cell.row.original.id} />
                    </div>
                )
            },
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                const imageUrl = ASSET_URL + row.avatar;
                return (
                    <div className="d-flex">
                        <ViewUserModal data={row}>
                            <img className="me-2 rounded-circle header-profile-user" src={imageUrl} alt="User Avatar" />
                        </ViewUserModal>
                        <div className="flex-grow-1" data-id="1">
                            <h5 className="fs-13 mb-1">
                                <a href="#" className="link text-dark"></a>
                                <a href="#">{row.first_name} {row.last_name}</a>
                            </h5>
                            <p className="text-muted mb-0"><a href={`tel:${row.phone}`}><i className='bx bx-phone text-success' />{row.phone}</a> | <i className='bx bx-mail-send' />{row.email}</p>
                        </div>
                        <div className="flex-shrink-0">
                            <div>
                                <Switch
                                    className={row.is_active ? 'bg-primary me-2' : 'me-2'}
                                    loading={row.changing ? true : false}
                                    checked={row.is_active}
                                    onChange={() => handleUserStateChange(row)}
                                    checkedChildren={(<span style={{ fontSize: "10px" }}>Active</span>)}
                                    unCheckedChildren={(<span style={{ fontSize: "10px" }}>Deactive</span>)}
                                />
                                <UpdateAllUserModal data={row} userType='Student' apiHandler={apiHandler} />
                                <button onClick={() => handleDelete({ name: row.first_name, id: row.id })} className="btn btn-sm btn-soft-danger me-1" data-id="1"> <i className="ri-delete-bin-fill"></i> </button>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    ]);
    return (
        <>
            <BreadCrumb title="Students" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-md-flex d-grid align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Students List</h5>
                            <div className="d-flex justify-content-between gap-4 mt-2 mt-md-0">
                                <ImposeFeeModal className='' />
                                <ViewFeeDetailsModal className='' />
                                <ChangeFamilyModal className='' />
                            </div>
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isLoading={tableLoading} columns={columns} data={tableData} noRecord="No Record Student List" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default Students