import React, { useEffect, useState } from 'react'
import SimpleBar from 'simplebar-react';
import BreadCrumb from '../../../components/common/BreadCrumb'
import { Card, CardBody, CardHeader, Col, Row, Button } from "react-bootstrap";
import CustomSelect from '../../../components/CustomSelect';
import { Link } from 'react-router-dom';
import { ASSET_URL, attendance, course } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { formatDate } from '../../../helper/formatDate';
import { useDispatch } from "react-redux";
import { setPreloader } from '../../../features/Ui/uiSlice';



export const Attendance = () => {
    const [courses, setCourses] = useState([]);
    const [courseStudents, setCourseStudents] = useState([]);
    const [selectedClass,setSelectedClass] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        course.list().then(r => setCourses(r.data.course)).catch(e => console.error(e));
    }, [])
    const handleGetCourseStudents = (e) => {
        dispatch(setPreloader({ loader: true, message: "Fetching Students Please wait ..." }));
        setSelectedClass(e.value);
        setCourseStudents([]);
        course.students(e.value)
        .then(r => { 
            setCourseStudents(r.data.student); 
            document.getElementById('showSaveAttendance').className = 'd-flex align-items-start gap-3 mt-4 d-block'; 
            document.getElementById('showSaveAttendanceM').className = 'd-flex align-items-start gap-3 mt-4 d-block';
        })
        .catch(err => swal.error('Error', err.response ? err.response.data.message : err.message))
        .finally(()=>dispatch(setPreloader({ loader: false, message: "" })))
    }
    const handleSaveAttendance = (e) => {
        dispatch(setPreloader({ loader: true, message: "Saving Attendance Please wait ..." }));
        e.preventDefault();
        const formData = new FormData(e.target);
        const studentsAttendance = {
            attendanceData: courseStudents.map(student => ({
                user_id: student?.id,
                attendance: formData.get(`attendance-${student.id}`),
            })),
            class_id:selectedClass,
            attendance_date: formatDate(Date.now(),'YYYY-MM-DD'),
        };
        attendance.add(studentsAttendance).then(res => {
            console.log(res)
        }).catch(err => swal.error('Oppsss...', err.response ? err.response.data.message : err.message))
        .finally(()=>dispatch(setPreloader({ loader: false, message: "" })))
    }
    return (
        <>
            <style>
                {`
                    
                    .form-check-input:checked.rd-success {
                        background-color: #0ab39c; 
                    }

                    .form-check-input:checked.rd-danger {
                        background-color: #f06548; 
                    }

                    .form-check-input:checked.rd-warning {
                        background-color: #f7b84b; 
                    }
                `}
            </style>
            <BreadCrumb title="Attendance" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Attendance</h5>
                            <Link to="/attendance-list" className='btn btn-soft-dark add-btn waves-effect me-2'>
                                <i className="ri-contacts-fill"></i>
                            </Link>
                        </CardHeader>
                        <CardBody className="">
                            <Row className='my-3'>
                                <div className="col-12 mt-2">
                                    <div>
                                        <label htmlFor="select_class_input" className="form-label">Select A Class</label>
                                        <CustomSelect id="select_class_input" onChange={handleGetCourseStudents} isSearchable options={courses?.map(i => ({ label: i.name, value: i.id }))} />
                                    </div>
                                </div>
                            </Row>

                            <div className='d-none d-lg-block'>
                                <form onSubmit={handleSaveAttendance}>

                                    <table className="table table-bordered dt-responsive nowrap table-striped align-middle">
                                        <thead>
                                            <tr>
                                                <th width="10px">SrNo.</th>
                                                <th width="200px">Name</th>
                                                <th width="10px">contact</th>
                                                <th className='text-center'><i className='bx bx-left-arrow-alt' /></th>
                                                <th className='text-center'>{formatDate(Date.now())}</th>
                                                <th className='text-center'><i className='bx bx-right-arrow-alt' /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {courseStudents?.map((student, index) => (
                                                <tr key={index}>
                                                    <td width="10px">{index + 1}</td>
                                                    <td width="200px"><span> <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={ASSET_URL + student.avatar} alt="User Avatar" /> {student.first_name} {student.last_name}</span></td>
                                                    <td width="10px">{student.phone}</td>
                                                    <td colSpan={3}>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <div className="form-check text-end">
                                                                    <label className="form-check-label" htmlFor={`present-${student.id}`}>
                                                                        <input style={{fontSize:'20px'}} className="form-check-input rd-success me-2" type="radio" name={`attendance-${student.id}`} id={`present-${student.id}`} value="P" />
                                                                        Present
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-4'>
                                                                <div className="form-check">
                                                                    <label className="form-check-label" htmlFor={`absent-${student.id}`}>
                                                                        <input style={{fontSize:'20px'}} className="form-check-input rd-danger me-2 fs-20" type="radio" name={`attendance-${student.id}`} id={`absent-${student.id}`} value="A" />
                                                                        Absent
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col-4'>
                                                                <div className="form-check">
                                                                    <label className="form-check-label" htmlFor={`leave-${student.id}`}>
                                                                        <input style={{fontSize:'20px'}} className="form-check-input rd-warning me-2" type="radio" name={`attendance-${student.id}`} id={`leave-${student.id}`} value="L" />
                                                                        On Leave
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="d-lg-flex align-items-start gap-3 mt-4 d-none" id='showSaveAttendance'>
                                        <button type="submit" className="btn btn-primary btn-label right ms-auto nexttab nexttab" ><i className="bx bxs-send label-icon align-middle fs-16 ms-2"></i>Save</button>
                                    </div>
                                </form>
                            </div>
                            <div className="d-lg-none">
                                <div id="user_list_lst_container">
                                    <div className='row mb-3'>
                                        <div className='col'>
                                            {/* <input type="text" className='form-control' placeholder="Search..." value={searchQuery} onChange={e => setSearchQuery(e.target.value)} /> */}
                                        </div>
                                    </div>
                                    <form onSubmit={handleSaveAttendance}>
                                        <ul className="list list-group list-group-flush mb-0">
                                            <li className='list-group-item'>
                                                <Row>
                                                    <Col xs={6}><span className='fw-bold'>Candidate Name </span></Col>
                                                    <Col className='text-center' xs={6}>
                                                        <span className='text-wrap'>{formatDate(Date.now())} </span>
                                                    </Col>
                                                </Row>
                                            </li>
                                            <SimpleBar style={{ height: "calc(100vh - 376px)" }}>
                                                {courseStudents?.map((student, index) => (
                                                    <li key={index} className="list-group-item py-3">
                                                        <Row>
                                                            <Col xs={6}><span className='fw-bold'><span> <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={ASSET_URL + student.avatar} alt="User Avatar" /> {student.first_name} {student.last_name}</span> </span></Col>

                                                            <Col className='d-flex align-items-center justify-content-between' xs={6}>
                                                                <div className="form-check d-flex align-items-center">
                                                                    <label className="form-check-label m-0" style={{lineHeight:'24px'}} htmlFor={`present-${student.id}`}> P </label>
                                                                    <input className="form-check-input rd-success" style={{fontSize:'20px'}} type="radio" name={`attendance-${student.id}`} id={`present-${student.id}`} value="P" />
                                                                </div>
                                                                
                                                                <div className="form-check d-flex align-items-center">
                                                                    <label className="form-check-label m-0" style={{lineHeight:'24px'}} htmlFor={`absent-${student.id}`}> A </label>
                                                                    <input className="form-check-input rd-danger" style={{fontSize:'24px'}} type="radio" name={`attendance-${student.id}`} id={`absent-${student.id}`} value="A" />
                                                                </div>
                                                                
                                                                <div className="form-check d-flex align-items-center">
                                                                    <label className="form-check-label m-0" style={{lineHeight:'24px'}} htmlFor={`leave-${student.id}`}> L </label>
                                                                    <input className="form-check-input rd-warning" style={{fontSize:'24px'}} type="radio" name={`attendance-${student.id}`} id={`leave-${student.id}`} value="L" />
                                                                </div>
                                                                
                                                            </Col>
                                                        </Row>
                                                    </li>

                                                ))}
                                            </SimpleBar>
                                        </ul>
                                        <div className="d-flex align-items-start gap-3 mt-4 d-lg-none" id='showSaveAttendanceM'>
                                            <button type="submit" className="btn btn-primary btn-label right ms-auto nexttab nexttab" ><i className="bx bxs-send label-icon align-middle fs-16 ms-2"></i>Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
