import React, { useEffect, useMemo, useState } from 'react'
import { ASSET_URL, fee } from '../../../../helper/api_url'
import { TableResponsive } from '../../../../components/common/TableResponsive';
import { differenceInMonths, parseISO } from 'date-fns';
import FeesPaymentModal from '../../Accounts/FeesPaymentModal';
import { current_dues } from '../../../../helper/dues_calculators';
import { FeeButton } from '../../Accounts/FeeButton';

function StudentFees({course_id,activeTab}) {
    const [duesList,setDuesList] = useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    useEffect(()=>{
        setTableLoading(true);
        if(course_id && activeTab === 3) fee.courseDuesList(course_id)
        .then(res=>setDuesList(res.data.fee_dues))
        .catch(e=>console.error(e.response?e.response.data.message:e.message))
        .finally(()=>setTableLoading(false));
    },[activeTab])
    const columns = useMemo(()=>[
        {Header: "SrNo",accessor: "id", Cell: cell => {return (<p className="">{cell.row.index+1}</p>)},HeaderClass:'text-center', DataClass:'text-center'},
        {
            Header: "Name",
            accessor: "first_name",
            HeaderClass:'',
            DataClass:'',
            Cell:(cell)=> {
                const imageUrl =ASSET_URL + cell.row.original.avatar;
                return (<span> <img className="me-2 rounded-circle header-profile-user" style={{objectFit:'cover'}} src={imageUrl} alt="User Avatar" />{cell.row.original.first_name} {cell.row.original.last_name}</span>)
            }
        },
        {Header: "Phone",accessor: "phone",HeaderClass:'text-center', DataClass:'text-center'},
        {
            Header:'Current Dues',
            HeaderClass:'text-center', 
            DataClass:'text-center',
            Cell:cell=>{
                const row = cell.row.original;
                const dues = row.due_fee.filter(f=>{
                    const lastPaymentDate = new Date(f.last_payment_date);
                    const now = Date.now();
                    return lastPaymentDate < now;
                }).reduce((acc,index)=>{
                    const paid_amount = index.fees_payment?.reduce((total,txn)=>total+(parseFloat(txn.paid_amt)),0)
                    return acc + parseFloat(index.imposed_amount) - paid_amount;
                },0)
                return dues>=0?dues:0;
            }
        },
        {
            Header:'Action',
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell:cell=>(<div>
                <FeesPaymentModal row_data={cell.row.original} setDuesData={setDuesList} />
                <span className='mx-2'></span>
                <FeeButton id={cell.row.original.id} />
            </div>)
        },
        {
            Header: "List",
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                const imageUrl = ASSET_URL+row.avatar;
                row.due_fee = row.due_fee.filter(f=>{
                    const lastPaymentDate = new Date(f.last_payment_date);
                    const now = Date.now();
                    return lastPaymentDate < now;
                })
                return (
                    <div className="d-flex">
                    <img className="me-2 rounded-circle header-profile-user" src={imageUrl} alt="User Avatar" />
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#" className="link text-dark"></a>
                            <a href="#">{row.first_name} {row.last_name}</a>
                        </h5>
                        <p className="text-muted mb-0">
                            <a href={`tel:${row.phone}`}><i className='bx bx-phone text-success' />{row.phone}</a>
                            <span className='badge badge-soft-warning mx-2' >Dues : {current_dues(row)}</span>
                        </p>
                        

                    </div>
                    <div className="flex-shrink-0">
                        <div>
                            <FeesPaymentModal row_data={row} setDuesData={setDuesList} />
                            <span className='mx-2'></span>
                            <FeeButton id={row.id} />
                        </div>
                    </div>
                </div>
                )
            }
        }
    ],[])
    return (
        <TableResponsive isLoading={tableLoading} columns={columns} data={duesList} />
    )
}

export default StudentFees