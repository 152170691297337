import React, { useEffect, useMemo } from 'react';
import { Modal, Row, Col} from 'react-bootstrap'
import { useModalHandler } from '../../../helper/custom_hook';
import { useDispatch } from "react-redux";
import CustomSelect from '../../../components/CustomSelect';
import { fee } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { TableResponsive } from '../../../components/common/TableResponsive';
import { useNavigate } from 'react-router-dom';
import { current_dues, given_fee_current_dues } from '../../../helper/dues_calculators';

function DuesDescriptionModal({row_data,setDuesData}) {
    const {status,toggleModal} = useModalHandler();
    const dispatch = useDispatch();
    useEffect(()=>{
        if(status) setTotalPayableAmount();
    },[status])
    const handleAmountChange = e => setTotalPayableAmount();
    const setTotalPayableAmount = () => {
        const deposit_inputs = document.getElementsByClassName('deposit_input')
        const totalPayable = Array.from(deposit_inputs).reduce((total,input)=>total+parseFloat(input.value || 0),0);
        // document.getElementById('total_payable_amount').value = totalPayable;
    }
    const navigate = useNavigate();
    const handleViewInvoice = id => navigate('/view-invoice', { state: { invoiceId:id } });
    const handlePaymentSubmit = e => {
        e.preventDefault();
        swal.info('This Feature is comming soon');

    }
    const handlePaymentSubmitListView = e => {
        e.preventDefault();
        swal.info('Unsupported Format','Feature Unavailable on Phone, Please Use a Computer');
    }
    const tableColumns = useMemo(()=>[
        {
            Header: "Name",
            DataClass:'text-center',
            HeaderClass:'text-center',
            accessor:'name'
        },
        {
            Header: "Amount",
            DataClass:'text-center',
            HeaderClass:'text-center',
            Cell:cell=>{
                const row = cell.row.original;
                return <span className="badge p-2 badge-outline-warning">{row.imposed_amount} X {row.total_installments}</span>
            }
        },
        {
            Header: "Type",
            DataClass:'text-center',
            HeaderClass:'text-center',
            accessor:"frequency_type"
        },
        {
            Header: "Total Amount",
            DataClass:'text-center',
            HeaderClass:'text-center',
            Cell:cell=>{
                const row = cell.row.original;
                const dues = (row.imposed_amount * row.total_installments);
                return <span>{dues}</span>
            }
        },
        {
            Header: "Total paid",
            DataClass:'text-center',
            HeaderClass:'text-center',
            Cell:cell=>{
                const row = cell.row.original;
                return <span>{row.fees_payment.reduce((acc,f)=>acc + f?.paid_amt,0)}</span>
            }
        },
        {
            Header: "Current Dues",
            DataClass:'text-center bg-soft-danger',
            HeaderClass:'text-center bg-soft-danger',
            Cell:cell=>{
                const row = cell.row.original;
                return <span>{given_fee_current_dues(row)}</span>
            }
        },
    ])
    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-success me-1'><i className="ri-eye-fill me-2" /></button>
            <Modal className="fade" size='lg' centered={true} backdrop="static" show={status} onHide={toggleModal} >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5>Dues payment - {row_data?.first_name} {row_data?.last_name}</h5>
                        </Modal.Title>
                    </Modal.Header>
                <form className='d-none d-lg-block' onSubmit={handlePaymentSubmit}>
                    <Modal.Body>
                        <TableResponsive 
                            data={row_data.due_fee.filter(dues=>((dues.imposed_amount * dues.total_installments)-(dues?.fees_payment?.reduce((acc,f)=>acc + f?.paid_amt,0)))>0)} 
                            columns={tableColumns} 
                            isPagination={false} 
                            showFilter={false} 
                            showCustomOptionPage={false} 
                            isShowingPageLength={false} 
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' className='btn btn-success'>Generate Demand Bill</button>
                    </Modal.Footer>
                </form>
                <form className='d-block d-lg-none' onSubmit={handlePaymentSubmitListView}>
                    <Modal.Body>
                        <ul className="list list-group list-group-flush mb-0">
                            {row_data.due_fee
                            .filter(dues=>((dues.imposed_amount * dues.total_installments)-(dues?.fees_payment?.reduce((acc,f)=>acc + f?.paid_amt,0)))>0)
                            .map((dues,index)=>(
                                <li key={index} className="list-group-item ">
                                    <div className="d-flex">
                                        <div className="flex-grow-1" data-id="1">
                                            <h5>{dues.name}</h5>
                                            <p>Current Dues : {given_fee_current_dues(dues)}</p>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div>
                                                <input 
                                                    type='number' 
                                                    id={'deposit_list_'+dues.id} 
                                                    step={0.01} 
                                                    defaultValue={given_fee_current_dues(dues)} 
                                                    className='form-control' style={{width:'120px'}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' className='btn btn-success'> Generate Demand Bill</button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default DuesDescriptionModal