import React, { useEffect, useState } from 'react'
import { Modal} from 'react-bootstrap'
import { swal } from '../../../helper/swal';
import Camera from '../../../components/common/Camera';
import SimpleBar from 'simplebar-react';
import { ASSET_URL, allUser,enquiry } from '../../../helper/api_url';
import ModalProfileImage from '../../../components/common/ModalProfileImage';
import { useModalHandler, useWebCam } from '../../../helper/custom_hook';
import CustomSelect from '../../../components/CustomSelect';

export function AllUserModal({ apiHandler, userType = false }) {
    const [allUserRoles,setAllUserRoles] = useState([]);
    const [enquiryData,setEnquiryData] = useState([]);
    const {genders,toggleModal,status,userProfile,setUserProfile} = useModalHandler();
    const {webcam,toggleWebcam,setCapturedImageToInput} = useWebCam(setUserProfile);
    useEffect(()=>{
        if (status)
        allUser.roles()
            .then((res) =>setAllUserRoles([...res.data.role.map((role) => {return { value: role.id, label: role.name };}),]))
            .catch((err) =>console.log(err.response ? err.response.data.message : err.message));
    },[status])

    const fetchEnquiryData =(e)=>{
        const enquiry_id=document.querySelector("#enquiry_id").value;
        enquiry_id?enquiry.fetchSingle(enquiry_id).then(r=>{setEnquiryData(r.data.enquiry);setUserProfile(ASSET_URL+r.data.enquiry.avatar);document.querySelector("#enquiryDataget").className='d-none';document.querySelector("#enquiryDataset").className='d-block'}).catch(err=>{swal.error(err.response?err.response.data.message:err.message);document.querySelector("#enquiryDataset").className='d-none';}):document.querySelector("#enquiryDataset").className='d-none'
    }
    return (
        <>
            <button onClick={toggleModal} className='btn btn-soft-success add-btn waves-effect'>
                <i className="ri-add-line align-bottom me-1"></i>
                <span>{(userType=='Student')?'Add Addmission':'Add User'}</span>
            </button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>{(userType=='Student')?'Add Addmission':'Add User'}</h5></Modal.Title>
                </Modal.Header>

                {(userType=='Student')? 
                <>
                <div id='enquiryDataget' className='d-block'>
                <Modal.Body >
                <div className="row g-3">
                <div className="col-12">
                    <div>
                        <label htmlFor="enquiry_id" className="form-label">Enquiry Id</label>
                        <input type="number" className="form-control" id='enquiry_id' name="enquiry_id" placeholder="Enter Enquiry ID" />
                    </div>
                </div>
                </div>
                <div className="col-lg-12 mt-3">
                    <div className="hstack gap-2 justify-content-end">
                        <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={fetchEnquiryData}>Next</button>
                    </div>
                </div>
                </Modal.Body>
                </div>
                   <form onSubmit={e => {apiHandler.handleSubmit(e,allUser.users,toggleModal)}} id='enquiryDataset' className='d-none'>
                   <SimpleBar id="scrollbar" style={{ maxHeight: "calc(90vh - 100px)" }} >
                <Modal.Body>
                   <div className='position-relative'>
                           {webcam ? (
                               <div className='position-absolute top-0 bottom-0 d-flex justify-content-center align-items-center start-0 end-0 modal-content border-0' style={{ zIndex: 9 }}>
                                   <Camera onSave={(file) => { setCapturedImageToInput(file,document.getElementById('avatarInput'),setUserProfile); toggleWebcam() }} onClose={() => toggleWebcam()} />
                               </div>) : null}
                       <div className="row g-3">
                       {userProfile && <ModalProfileImage viewimage={userProfile} />}
                           <div className="col-6">
                            <input type="hidden" name="enquiry_avatar" defaultValue={enquiryData?enquiryData.avatar:null} />
                               <div>
                                   <label htmlFor="firstName" className="form-label">First Name</label>
                                   <input type="text" className="form-control" id='firstName' name="first_name" defaultValue={enquiryData?enquiryData.name:''} placeholder="Enter firstname" />
                               </div>
                           </div>
                           <div className="col-6">
                               <div>
                                   <label htmlFor="lastName" className="form-label">Last Name</label>
                                   <input type="text" className="form-control" id='lastName' defaultValue={''} name="last_name" placeholder="Enter lastname" />
                               </div>
                           </div>
                           <div className="col-lg-12">
                               <label htmlFor="genderInput" className="form-label">Gender</label>
                               <CustomSelect id="genderInput" options={genders}  isSearchable name='gender' />
                           </div>
                           <div className='col-lg-6'>
                               <label htmlFor="dob" className="form-label">Date Of Birth</label>
                               <input type="date" id="dob" name='dob' defaultValue={enquiryData?enquiryData.dob:''} className='form-control' />
                           </div>
                           <div className='col-lg-6'>
                               <label htmlFor="role" className="form-label">User Type</label>
                               {(userType) ? (<>
                                   <input type='hidden' value={allUserRoles.length ? allUserRoles.find(r => r.label === userType).value : ''} name='role_id' />
                                   <input readOnly className='form-control' name='' value={allUserRoles.length ? allUserRoles.find(r => r.label === userType).label : 'NoRole'} />
                               </>) : (
                                   <select id="role" name='role_id' defaultValue='driver' className='form-control'>
                                       {allUserRoles.length ? allUserRoles.map((user, idx) => (<option key={idx} value={user.value}>{user.label}</option>)) : (<option disabled >No user Roles Found</option>)}
                                   </select>
                               )}
                           </div>
                           <div className="col-6">
                               <div>
                                   <label htmlFor="emailInput" className="form-label">Email</label>
                                   <input type="email" className="form-control" id="emailInput" name='email' defaultValue={''} placeholder="Enter your email" />
                               </div>
                           </div>
                           <div className="col-6">
                               <div>
                                   <label htmlFor="phoneNumber" className="form-label">Phone</label>
                                   <input type="tel" className="form-control" name='phone' defaultValue={enquiryData?enquiryData.phone:''} id="phoneNumber" />
                               </div>
                           </div>
                           <div className='col-10'>
                                       <label htmlFor="avatarInput" className="form-label">Profile Image</label>
                                       <input type="file" name="avatar" id="avatarInput" onChange={(e) => setCapturedImageToInput(e.target.files[0],document.getElementById('avatarInput'),setUserProfile)} className='form-control' />
                                   </div>
                           <div className='col-2'>
                               <label htmlFor="avatarInput" className="form-label ">Capture</label>
                               <button onClick={toggleWebcam} type='button' className='btn btn-soft-dark form-control'><i className='bx bx-camera'></i></button>
                           </div>
                           <div className="col-12">
                               <div>
                                   <label htmlFor="passwordInput" className="form-label">Password</label>
                                   <input type="password" className="form-control" name='password' id="passwordInput" />
                               </div>
                           </div>
                       </div>
                       </div>
               </Modal.Body>
               </SimpleBar>
                   <Modal.Footer>
                           <div className="col-lg-12">
                               <div className="hstack gap-2 justify-content-end">
                                   <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                   <button type="submit" className="btn btn-primary">Submit</button>
                               </div>
                           </div>
                   </Modal.Footer>
               </form>
               </>
                : 
                <form onSubmit={e => apiHandler.handleSubmit(e,allUser.users,toggleModal)}>
                <SimpleBar id="scrollbar" style={{ maxHeight: "calc(90vh - 100px)" }} >
            <Modal.Body>
                <div className='position-relative'>
                        {webcam ? (
                            <div className='position-absolute top-0 bottom-0 d-flex justify-content-center align-items-center start-0 end-0 modal-content border-0' style={{ zIndex: 9 }}>
                                <Camera onSave={(file) => { setCapturedImageToInput(file,document.getElementById('avatarInput'),setUserProfile); toggleWebcam() }} onClose={() => toggleWebcam()} />
                            </div>) : null}
                    <div className="row g-3">
                    {userProfile && <ModalProfileImage viewimage={userProfile} />}
                        <div className="col-6">
                            <div>
                                <label htmlFor="firstName" className="form-label">First Name</label>
                                <input type="text" className="form-control" id='firstName' name="first_name" placeholder="Enter firstname" />
                            </div>
                        </div>
                        <div className="col-6">
                            <div>
                                <label htmlFor="lastName" className="form-label">Last Name</label>
                                <input type="text" className="form-control" id='lastName' name="last_name" placeholder="Enter lastname" />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <label htmlFor="genderInput" className="form-label">Gender</label>
                            <CustomSelect id="genderInput" options={genders}  isSearchable name='gender' />
                        </div>
                        <div className='col-lg-6'>
                            <label htmlFor="dob" className="form-label">Date Of Birth</label>
                            <input type="date" id="dob" name='dob' className='form-control' />
                        </div>
                        <div className='col-lg-6'>
                            <label htmlFor="role" className="form-label">User Type</label>
                            {(userType) ? (<>
                                <input type='hidden' value={allUserRoles.length ? allUserRoles.find(r => r.label === userType).value : ''} name='role_id' />
                                <input readOnly className='form-control' name='' value={allUserRoles.length ? allUserRoles.find(r => r.label === userType).label : 'NoRole'} />
                            </>) : (
                                <select id="role" name='role_id' defaultValue='driver' className='form-control'>
                                    {allUserRoles.length ? allUserRoles.map((user, idx) => (<option key={idx} value={user.value}>{user.label}</option>)) : (<option disabled >No user Roles Found</option>)}
                                </select>
                            )}
                        </div>
                        <div className="col-6">
                            <div>
                                <label htmlFor="emailInput" className="form-label">Email</label>
                                <input type="email" className="form-control" id="emailInput" name='email' placeholder="Enter your email" />
                            </div>
                        </div>
                        <div className="col-6">
                            <div>
                                <label htmlFor="phoneNumber" className="form-label">Phone</label>
                                <input type="tel" className="form-control" name='phone' id="phoneNumber" />
                            </div>
                        </div>
                        <div className='col-10'>
                                    <label htmlFor="avatarInput" className="form-label">Profile Image</label>
                                    <input type="file" name="avatar" id="avatarInput" onChange={(e) => setCapturedImageToInput(e.target.files[0],document.getElementById('avatarInput'),setUserProfile)} className='form-control' />
                                </div>
                        <div className='col-2'>
                            <label htmlFor="avatarInput" className="form-label ">Capture</label>
                            <button onClick={toggleWebcam} type='button' className='btn btn-soft-dark form-control'><i className='bx bx-camera'></i></button>
                        </div>
                        <div className="col-12">
                            <div>
                                <label htmlFor="passwordInput" className="form-label">Password</label>
                                <input type="password" className="form-control" name='password' id="passwordInput" />
                            </div>
                        </div>
                    </div>
                    </div>
            </Modal.Body>
            </SimpleBar>
                <Modal.Footer>
                        <div className="col-lg-12">
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                </Modal.Footer>
            </form>}
                    
            </Modal>
        </>
    )
}