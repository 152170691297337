import React, { useEffect, useState } from 'react';
import { useModalHandler } from '../../../helper/custom_hook';
import { Modal, Row, Col } from 'react-bootstrap';
import CustomSelect from '../../../components/CustomSelect';
import { course, fee, student } from '../../../helper/api_url';
import DataLoading from '../../../components/DataLoading';
import { swal } from '../../../helper/swal';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';

function ImposeFeeModal({className=''}) {
    const { status, toggleModal } = useModalHandler();
    const [isLoading, setIsLoading] = useState(true);
    const [fees, setFees] = useState([]);
    const [selectedFee, setSelectedFee] = useState(null);
    const [students, setStudents] = useState([]);
    const [courses, setCourses] = useState([]);


    const dispatch = useDispatch();
    useEffect(() => {
        if (status) {
            fee.list().then(res => setFees(res.data.fee)).catch(e => console.error(e));
            course.list().then(res => setCourses(res.data.course))
                .catch(e => e.response ? e.response.message : e.message)
                .finally(() => setIsLoading(false));
        }
    }, [status]);
    const handleFeeImpose = e => {
        e.preventDefault();
        dispatch(setPreloader({ loader: true, message: 'please wait ... ' }));
        const formData = new FormData(e.target);
        fee.impose_to_users(formData).then(r => swal.success(r.message, 'Done'))
            .catch(e => swal.error(e.response ? e.response.data.message : e.message))
            .finally(() => dispatch(setPreloader({ loader: false, message: '' })))

    }
    const fetchCourseStudentList = e => {
        setStudents([]);
        const course_id = e.value;
        course.students(course_id).then(res => setStudents(res.data.student))
            .catch(e => swal.error(e.response ? e.response.data.message : e.message))
    }
    return (
        <>
            <button onClick={toggleModal} className={`btn btn-soft-dark ${className}`}><i className="ri-add-line align-bottom me-1"></i>Impose Fee</button>
            <Modal className="fade" size='md' centered={true} backdrop="static" show={status} onHide={toggleModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Impose A New Fee on Student</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleFeeImpose}>
                    <Modal.Body>
                        {isLoading ? (<DataLoading />) : (
                            <>
                                <Row>
                                    <Col lg={12} className="mb-2">
                                        <div>
                                            <label className='control-label'>Select A Class</label>
                                            <CustomSelect
                                                onChange={fetchCourseStudentList}
                                                isSearchable
                                                options={courses.map(c => ({ label: c.name, value: c.id }))}
                                                placeholder="select Class"
                                            />
                                        </div>
                                    </Col>
                                    
                                    <Col lg={12} className="mb-2">
                                        <div>
                                            <label className='control-label'>Select A Student</label>
                                            <CustomSelect 
                                                name="user_id"
                                                options={students.map(s => ({
                                                    label: `#${s.id} - ${s.first_name} ${s.last_name}`,
                                                    value: s.id
                                                }))}
                                                placeholder="select student"
                                                isSearchable
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} className='mb-2' >
                                        <div>
                                            <label className='control-label'>Select A Fee</label>
                                            <CustomSelect 
                                                name="fee_id" 
                                                options={fees.map(f => ({ label: f.name + "  (" + f.type + ")", value: f.id }))} 
                                                isSearchable 
                                                onChange={(e)=>setSelectedFee(fees.find(f=>f.id==e.value))}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={6} className='mb-2' >
                                        <div>
                                            <label className='control-label'>Fee Amount</label>
                                            <input type='number' step={0.01} disabled value={selectedFee?.amount} className='form-control' />
                                        </div>
                                    </Col>
                                    <Col xs={6} className='mb-2' >
                                        <div>
                                            <label className='control-label'>Final Amount</label>
                                            <input type='number' name="fee_amount" className='form-control' />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="mt-4">
                                    <div className="alert alert-warning">
                                        <p className="mb-0"><span className="fw-semibold">NOTES: </span>
                                            <span id="note">
                                                Make sure you have already created fees.
                                                For one-time fees, the last payment date is the date of imposition.
                                                For monthly fees, the impose date is 1st of each month from current month to end of session (march).
                                                Last payment date of monthly fees is 1st of each month including current month to end of session (march).
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-soft-info btn-label right ms-auto" >
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i> Impose
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default ImposeFeeModal