import { Modal, Row, Col } from "react-bootstrap";
import { useModalHandler } from "../../../helper/custom_hook";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { swal } from "../../../helper/swal";
import { family } from "../../../helper/api_url";

const NewFamilyModel = ({ className }) => {
	const { status, toggleModal } = useModalHandler();
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: (formData) => family.add(formData),
		onSuccess: (res) => {
			queryClient.setQueryData(['families'], (oldData) => {
				if (!oldData) return oldData;
				return {
					...oldData,
					data: {
						...oldData.data,
						family: [res.data.family, ...oldData.data.family]
					}
				};
			});
			
			swal.success(res.data.message);
			toggleModal();
		},
		onError: (err) => {
			swal.error(err.response ? err.response.data.message : err.message);
		}
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		mutation.mutate(formData);
	};

	return (
		<>
		<button onClick={toggleModal} className={`btn btn-soft-primary ${className}`}>
			<i className="bx bx-plus align-middle me-1"> </i>Create New Family
		</button>
		<Modal className="fade" size="md" centered backdrop="static" show={status} onHide={toggleModal} >
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter"> Create New Family </Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form onSubmit={handleSubmit}>
					<Row className="g-3">
						<Col md={6}>
							<div>
								<label htmlFor="father_name" className="form-label"> Father Name </label>
								<input type="text" className="form-control" id="father_name" name="father_name" required />
							</div>
						</Col>
						<Col md={6}>
							<div>
								<label htmlFor="father_qualification" className="form-label"> Father Qualification </label>
								<input type="text" className="form-control" id="father_qualification" name="father_qualification" />
							</div>
						</Col>
						<Col md={6}>
							<div>
								<label htmlFor="father_occupation" className="form-label"> Father Occupation </label>
								<input type="text" className="form-control" id="father_occupation" name="father_occupation" />
							</div>
						</Col>
						<Col md={6}>
							<div>
								<label htmlFor="father_mobile" className="form-label"> Father Mobile </label>
								<input type="text" className="form-control" id="father_mobile" name="father_mobile" />
							</div>
						</Col>
						<Col md={6}>
							<div>
								<label htmlFor="father_aad" className="form-label"> Father Aadhaar ID </label>
								<input type="text" className="form-control" id="father_aad" name="father_aad" />
							</div>
						</Col>
						<Col md={6}>
							<div>
								<label htmlFor="father_photo" className="form-label"> Father Photo </label>
								<input type="file" className="form-control" id="father_photo" name="father_photo" />
							</div>
						</Col>
						<Col md={6}>
							<div>
								<label htmlFor="mother_name" className="form-label"> Mother Name </label>
								<input type="text" className="form-control" id="mother_name" name="mother_name" />
							</div>
						</Col>
						<Col md={6}>
							<div>
								<label htmlFor="mother_qualification" className="form-label"> Mother Qualification </label>
								<input type="text" className="form-control" id="mother_qualification" name="mother_qualification" />
							</div>
						</Col>
						<Col md={6}>
							<div>
								<label htmlFor="mother_occupation" className="form-label"> Mother Occupation </label>
								<input type="text" className="form-control" id="mother_occupation" name="mother_occupation" />
							</div>
						</Col>
						<Col md={6}>
							<div>
								<label htmlFor="mother_mobile" className="form-label"> Mother Mobile </label>
								<input type="text" className="form-control" id="mother_mobile" name="mother_mobile" />
							</div>
						</Col>
						<Col md={6}>
							<div>
								<label htmlFor="mother_aad" className="form-label"> Mother Aadhaar ID </label>
								<input type="text" className="form-control" id="mother_aad" name="mother_aad" />
							</div>
						</Col>
						<Col md={6}>
							<div>
								<label htmlFor="mother_photo" className="form-label"> Mother Photo </label>	
								<input type="file" className="form-control" id="mother_photo" name="mother_photo" />
							</div>
						</Col>
						<Col md={12} className="mt-3">
							<div className="hstack gap-2 justify-content-end">
								<button type="submit" className="btn btn-primary"> Save </button>
							</div>
						</Col>
					</Row>
				</form>
			</Modal.Body>
		</Modal>
		</>
	);
};

export default NewFamilyModel;
