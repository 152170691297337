import React, { useEffect, useMemo } from 'react'
import BreadCrumb from "../../../components/common/BreadCrumb";
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row, Button } from "react-bootstrap";
import { TableResponsive } from "../../../components/common/TableResponsive";
import {ViewEnquiryModal} from './ViewEnquiryModal';
import {UpateEnquiryModal} from './UpateEnquiryModal';
import { NewEnquiryModal} from './NewEnquiryModal';
import { useSelector } from 'react-redux';
import { enquiry,ASSET_URL } from '../../../helper/api_url';
import { formatDate } from '../../../helper/formatDate';
import { usePageInitialtor} from '../../../helper/custom_hook';
function Enquiry() {
    const navigate = useNavigate();
    const authData=useSelector(state=>state.auth._user);
    const {tableData,tableLoading,apiHandler} = usePageInitialtor(enquiry);
    const handleEnquiryDelete = (userId,name) =>apiHandler.handleDelete({name:name,id:userId});
    
    const handleReport=(data)=>{
        navigate('/report', { state: { data } });
      }
    const columns = useMemo(()=>[
        {
            Header: "Name",
            accessor: "name",
            HeaderClass:'',
            DataClass:'',
            Cell:(cell)=> {
                const imageUrl =ASSET_URL + cell.row.original.avatar;
                const trial_end = cell.row.original.tryal_date !=  null? new Date(cell.row.original.tryal_date):null;
                if(trial_end !=  null)trial_end.setDate(trial_end.getDate() + cell.row.original.tryal_period);
                return (<span> <img className="me-2 rounded-circle header-profile-user" style={{objectFit:'cover'}} src={imageUrl} alt="User Avatar" />
                {cell.row.original.name}
                {(trial_end !=  null)
                ?(trial_end > Date.now()
                    ?(<span className='badge badge-soft-success px-1 ms-1'>On Trail</span>)
                    :(<span className='badge badge-soft-danger px-1 ms-1'>Trail Expired</span>))
                :null}
                </span>)
            }
        },
        {Header:'Class',accessor:'course.code',DataClass:'bg-soft-warning text-center p-0',HeaderClass:'text-center'},
        {Header:'Phone',accessor:'phone'},
        {
            Header: "Enquiry By",
            Cell:(cell)=> {
                return (<span>{cell?.row?.original?.created_by?.first_name} {cell?.row?.original?.created_by?.last_name}</span>)
            }
        },
        {
            Header: "Date",
            Cell:(cell)=> {
                return (<span>{formatDate(cell.row.original.created_at)}</span>)
            }
        },
        {
            Header:'Status',
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell:(cell)=>{
                const row=cell.row.original.status;
                const status={
                    label:row=="other"?"Not Instrested":row,
                    classStatus:row=="pending"?"warning":(row==="completed"?"success":"danger")
                }
                return (<span className={`badge badge-outline-${status.classStatus}`}>{status.label}</span>)
            }
        },
        {
            Header: "Report",
            HeaderClass:authData.role.priority>=50?'text-center':'d-none',
            DataClass:authData.role.priority>=50?'text-center':'d-none',
            Cell:(cell)=> {
                const row = cell.row.original;
                return (<button className='btn btn-sm btn-soft-info add-btn waves-effect'onClick={()=> handleReport(row)}><i className="bx bx-history align-bottom mb-1 me-1" />Report</button>)
            }
        },
        {
            Header: "Action",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell: (cell) => {
              return ( 
                <div className="">
                  
                    <ViewEnquiryModal view={cell.row.original}  />

                    <UpateEnquiryModal data={cell.row.original} apiHandler={apiHandler} />

                    <Button onClick={()=>handleEnquiryDelete(cell.row.original.id,cell.row.original.name)} className="btn btn-sm btn-soft-danger me-1" >
                        <i className="ri-delete-bin-fill" />  
                    </Button>
                </div>
              )
            },
        },
        {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                const imageUrl =ASSET_URL + row.avatar;
                const trial_end = row.tryal_date !=  null? new Date(row.tryal_date):null;
                if(trial_end !=  null)trial_end.setDate(trial_end.getDate() + row.tryal_period);
                return (
                <div className="d-flex">
                        <ViewEnquiryModal view={row}>
                            <img className="me-2 rounded-circle header-profile-user"  src={imageUrl} alt="User Avatar" />
                        </ViewEnquiryModal>
                    <div className="flex-grow-1" data-id="1">
                        <ViewEnquiryModal view={row}>
                            <h5 className="fs-13 mb-1">
                                <a href="#" className="link text-dark"></a>
                                <a href="#">{row.name}</a>
                                {(trial_end !=  null)
                                ?(trial_end > Date.now()
                                    ?(<span className='badge badge-soft-success px-1 ms-1'>On Trail</span>)
                                    :(<span className='badge badge-soft-danger px-1 ms-1'>Trail Expired</span>))
                                :null}

                            </h5>
                        </ViewEnquiryModal>
                        <p className="text-muted mb-0"><a href={`tel:${row.phone}`}><i className='bx bx-phone text-success' />{row.phone}</a> | Applied for : {row.course.code}</p>
                    </div>
                    <div className="flex-shrink-0">
                        <div>
                            <UpateEnquiryModal data={row} apiHandler={apiHandler} />
                            <button onClick={()=>handleEnquiryDelete(row.id,row.name)} className="btn btn-sm btn-soft-danger me-1" data-id="1"> <i className="ri-delete-bin-fill"></i> </button>
                        </div>
                    </div>
                </div>
                )
            }
        }

    ]);

    return (
        <>
            <BreadCrumb title="Users" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Enquiry List</h5>
                            <NewEnquiryModal apiHandler={apiHandler} />
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isLoading={tableLoading} columns={columns} data={tableData} noRecord="No Record Enquiry List" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Enquiry