import React, { useEffect, useRef } from "react";
import Webcam from "react-webcam";
import jsQR from "jsqr";
import { Row, Col } from "react-bootstrap";
import img from "../../helper/image_helper";
const videoConstraints = {
  width: 540,
  facingMode: "environment",
};

const Camera = ({ onSave,onClose }) => {
  const webcamRef = useRef(null);
  const [url, setUrl] = React.useState(null);
  const [showWebcam, setShowWebcam] = React.useState(true);
  const reset=()=>setUrl(null);
  const capturePhoto = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUrl(imageSrc);
    setShowWebcam(false);    
  }, [webcamRef]);
  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };
  const save = ()=>{
    if(url){
        const base64Image = url.split(',')[1];
        const blob = b64toBlob(base64Image, 'image/jpeg');
        const file = new File([blob], 'captured_image.jpeg', { type: 'image/jpeg' });
        onSave(file)
    }
  };

  return (
    <>
        <div className="webcam-container overflow-hidden position-relative">
            {url != null ? (<img src={url} style={{ width: "100%" }} />):
            <div style={{width:"460px",height:'350px'}}>
                <Webcam
                    ref={webcamRef}
                    audio={false}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    style={{ width: "100%" }}
                />
            </div>
            }
        </div>
        <div className="d-flex justify-content-center mt-4">
            <button type="button" onClick={onClose} className="btn btn-light me-2">Close</button>
            <button type="button" onClick={reset} className="btn btn-light me-2">Reset</button>
            <button type="button" onClick={capturePhoto} disabled={url?true:false} className="btn btn-outline-primary me-2">Capture</button>
            <button type="button" disabled={url?false:true} onClick={save} className="btn btn-outline-success me-2" >Save</button>
        </div>
    </>
  );
};

export default Camera;
